import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
    AccordionItem,
    Box,
    Button,
    Flex,
    Input,
    Label,
    Link,
    Markdown,
    THEME_CONSTANTS,
    useBrand
} from '@cof/plastic-components';
import dataValidators from '@cof/data-validators';
import {
    errorBorderColor,
    borderBottomStyle,
    variantConditional,
    disabledInputColor
} from '../../utils/styleChanges';
import text from '../../text';
import appendBrandParam from '../../utils/appendBrandParam';
import ErrorHelperText from '../../_molecules/ErrorHelperText';

const { FONT_WEIGHTS } = THEME_CONSTANTS;

const EnterUsernameForm = ({ onSubmit, disabled, setDisabled }) => {
    const [formValues, setFormValues] = useState({ authField1: '' });
    const brand = useBrand();
    return (
        <Formik
            initialValues={formValues}
            validate={values => {
                const errors = {};

                if (!values.authField1) {
                    errors.authField1 = text.SharedText.errorText;
                } else if (!dataValidators.isValidUsername(values.authField1)) {
                    errors.authField1 = text.SharedText.errorText;
                }
                return errors;
            }}
            onSubmit={(values, formik) => {
                setDisabled(true);
                setFormValues(values);
                onSubmit(
                    {
                        authField1: values.authField1
                    },
                    formik
                );
            }}
            enableReinitialize
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit
            }) => (
                <form onSubmit={handleSubmit} data-qa-id="username-form">
                    <Box position="relative">
                        <div>
                            <Label
                                data-qa-id="username-label"
                                variant="dark"
                                htmlFor="authField1"
                                fontWeight={FONT_WEIGHTS.RG}
                                mt="0 !important"
                            >
                                {text.UsernamePage.fieldLabel}
                            </Label>
                        </div>
                        <Input
                            type="text"
                            id="authField1"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            data-qa-id="username-input"
                            disabled={disabled}
                            style={{
                                borderColor: errorBorderColor(
                                    errors.authField1,
                                    touched.authField1,
                                    disabled
                                ),
                                backgroundColor: disabledInputColor(disabled),
                                opacity: '1',
                                minWidth: '100%'
                            }}
                            value={values.authField1}
                            variant={variantConditional(
                                touched.authField1,
                                disabled,
                                errors.authField1
                            )}
                            autoFocus
                            mb={[null, 'xs']}
                        />
                        {touched.authField1 &&
                            !disabled &&
                            errors.authField1 && (
                                <ErrorHelperText
                                    qaId="username-input-error-change"
                                    id="username-error"
                                >
                                    {errors.authField1}
                                </ErrorHelperText>
                            )}
                    </Box>

                    <Box
                        flexGrow={[1, 0]}
                        display="flex"
                        justifyContent="center"
                    >
                        <Button
                            id="submitButton"
                            type="submit"
                            width={['100%', '256px', '256px']}
                            mt="md"
                            data-qa-id="username-form-submit"
                            mb="lg"
                            disabled={disabled}
                            loading={disabled}
                        >
                            {text.SharedText.submit}
                        </Button>
                    </Box>
                    <Flex>
                        <Box minWidth="100%">
                            <Box
                                style={{
                                    borderBottom: borderBottomStyle(
                                        false,
                                        brand
                                    )
                                }}
                                pb={['md', 'md']}
                            >
                                <Link
                                    href={appendBrandParam(
                                        brand,
                                        text.UsernamePage.helpLink.url
                                    )}
                                    rel="noopener noreferrer"
                                    size="medium"
                                    data-qa-id="help-link"
                                >
                                    {text.UsernamePage.helpLink.text}
                                </Link>
                            </Box>
                            <AccordionItem
                                heading={
                                    text.UsernamePage.usernameInput.usernameFAQ
                                        .summary
                                }
                                mt={['xs', null, 'sm']}
                                data-qa-id="username-input-accordion"
                            >
                                <Markdown data-qa-id="username-input-accordion-text">
                                    {
                                        text.UsernamePage.usernameInput
                                            .usernameFAQ.details
                                    }
                                </Markdown>
                            </AccordionItem>
                        </Box>
                    </Flex>
                </form>
            )}
        </Formik>
    );
};

EnterUsernameForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    setDisabled: PropTypes.func.isRequired
};

export default EnterUsernameForm;
