/* eslint-disable complexity */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
    AccordionItem,
    Box,
    Flex,
    Input,
    Label,
    Markdown,
    THEME_CONSTANTS,
    useBrand,
    LockIcon,
    Button
} from '@cof/plastic-components';
import { object, string } from 'yup';
import ValidationIndicator from '../../_molecules/ValidationIndicator';
import {
    isValidPassword,
    passwordRangeIsValid,
    passwordHasUpperLowerLetters,
    passwordContainsANumber,
    validatePasswordInput
} from '../../utils/validation';
import {
    borderBottomStyle,
    disabledInputColor,
    errorBorderColor
} from '../../utils/styleChanges';
import text from '../../text';
import ErrorHelperText from '../../_molecules/ErrorHelperText';

const { FONT_WEIGHTS } = THEME_CONSTANTS;

const generateSchema = () =>
    object().shape({
        authField2: string()
            .required(text.EnterPasswordPage.errorText.authField2Invalid)
            .test(
                'is-valid-password',
                text.EnterPasswordPage.errorText.authField2Invalid,
                isValidPassword
            ),
        authField3: string().required(
            text.EnterPasswordPage.errorText.authField3Invalid
        )
    });

const inputErrorColor = (touched, errors, disabled) => {
    return touched && !disabled && errors ? 'error' : null;
};

const ChoosePasswordForm = ({ onSubmit, disabled, setDisabled }) => {
    const brand = useBrand();
    const validationSchema = React.useMemo(() => generateSchema(), []);
    const [formValues, setFormValues] = useState({
        authField2: '',
        authField3: ''
    });
    return (
        <Formik
            initialValues={formValues}
            validationSchema={validationSchema}
            validate={values =>
                validatePasswordInput(
                    validationSchema,
                    values,
                    text.EnterPasswordPage.errorText.nonMatching
                )
            }
            onSubmit={(values, formik) => {
                setDisabled(true);
                setFormValues(values);
                onSubmit(
                    {
                        authField2: values.authField2,
                        authField3: values.authField3
                    },
                    formik
                );
            }}
            enableReinitialize
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit
            }) => (
                <form onSubmit={handleSubmit} data-qa-id="password-form">
                    <Box pt={['xs']} position="relative">
                        <Box>
                            <Label
                                data-qa-id="password-first-label"
                                variant="dark"
                                htmlFor="authField2"
                                fontWeight={FONT_WEIGHTS.RG}
                                mt="0 !important"
                                pt={[
                                    '0.8rem !important',
                                    '0rem !important',
                                    '0.8rem !important'
                                ]}
                            >
                                {text.EnterPasswordPage.passwordLabel}
                            </Label>
                        </Box>
                        <Input
                            type="password"
                            id="authField2"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            data-qa-id="password-first-textbox"
                            disabled={disabled}
                            style={{
                                borderColor: errorBorderColor(
                                    errors.authField2,
                                    touched.authField2,
                                    disabled
                                ),
                                backgroundColor: disabledInputColor(disabled),
                                opacity: '1',
                                minWidth: '100%'
                            }}
                            value={values.authField2}
                            variant={inputErrorColor(
                                touched.authField2,
                                errors.authField2,
                                disabled
                            )}
                            autoFocus
                            validateOnChange
                            validateOnBlur
                            mb="xs"
                        />
                        {touched.authField2 &&
                            !disabled &&
                            errors.authField2 && (
                                <ErrorHelperText
                                    qaId="password-first-error"
                                    id="password-first-error"
                                >
                                    {errors.authField2}
                                </ErrorHelperText>
                            )}
                        <ValidationIndicator
                            isValid={passwordRangeIsValid(values.authField2)}
                            text={text.EnterPasswordPage.validators.length}
                            qaId="password-length"
                            mt="xs"
                        />
                        <ValidationIndicator
                            isValid={passwordHasUpperLowerLetters(
                                values.authField2
                            )}
                            text={
                                text.EnterPasswordPage.validators
                                    .uppercaseLowercase
                            }
                            qaId="password-uppercase"
                        />
                        <ValidationIndicator
                            isValid={passwordContainsANumber(values.authField2)}
                            text={text.EnterPasswordPage.validators.number}
                            qaId="password-number"
                            mb="xs"
                        />
                    </Box>

                    <Box position="relative">
                        <Box>
                            <Label
                                data-qa-id="password-second-label"
                                variant="dark"
                                htmlFor="authField3"
                                fontWeight={FONT_WEIGHTS.RG}
                                mt="sm"
                            >
                                {text.EnterPasswordPage.confirmLabel}
                            </Label>
                        </Box>
                        <Input
                            type="password"
                            id="authField3"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            data-qa-id="password-second-textbox"
                            disabled={disabled}
                            style={{
                                borderColor: errorBorderColor(
                                    errors.authField3,
                                    touched.authField3,
                                    disabled
                                ),
                                backgroundColor: disabledInputColor(disabled),
                                opacity: '1',
                                minWidth: '100%'
                            }}
                            variant={inputErrorColor(
                                touched.authField3,
                                errors.authField3,
                                disabled
                            )}
                            value={values.authField3}
                            validateOnChange
                            validateOnBlur
                            mb={[null, 'xs']}
                        />
                        {touched.authField3 &&
                            !disabled &&
                            errors.authField3 && (
                                <ErrorHelperText
                                    qaId="password-second-error"
                                    id="password-second-error"
                                >
                                    {errors.authField3}
                                </ErrorHelperText>
                            )}
                    </Box>

                    <Box
                        flexGrow={[1, 0]}
                        style={{
                            borderBottom: borderBottomStyle(false, brand) // hard coding colour as theres a bug using parameterised colour
                        }}
                        display="flex"
                        justifyContent="center"
                    >
                        <Button
                            id="submitButton"
                            type="submit"
                            width={['100%', '256px', '256px']}
                            mt={['md']}
                            data-qa-id="password-submit"
                            mb="lg"
                            disabled={disabled}
                            loading={disabled}
                        >
                            <LockIcon
                                data-qa-id="password-lock-icon"
                                verticalAlign="middle"
                                display="inline-block"
                                style={{
                                    marginTop: '-0.5rem',
                                    paddingRight: '0.5rem',
                                    width: '2.2rem',
                                    height: '2.2rem'
                                }}
                            />
                            {text.EnterPasswordPage.submit}
                        </Button>
                    </Box>
                    <Flex>
                        <Box>
                            <AccordionItem
                                heading={
                                    text.EnterPasswordPage.passwordInput
                                        .passwordFAQ.summary
                                }
                                mt={['xs', null, 'sm']}
                                data-qa-id="password-accordion"
                            >
                                <Markdown data-qa-id="password-accordion-text">
                                    {
                                        text.EnterPasswordPage.passwordInput
                                            .passwordFAQ.details
                                    }
                                </Markdown>
                            </AccordionItem>
                        </Box>
                    </Flex>
                </form>
            )}
        </Formik>
    );
};

ChoosePasswordForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    setDisabled: PropTypes.func.isRequired
};

export default ChoosePasswordForm;
