import React from 'react';
import PropTypes from 'prop-types';
import { Box, DateInput, Flex, THEME_CONSTANTS } from '@cof/plastic-components';
import dataValidators from '@cof/data-validators';
import { disabledInputColor } from '../../utils/styleChanges';

const { FONT_WEIGHTS, FONT_SIZES, LINE_HEIGHTS, COLORS } = THEME_CONSTANTS;

const variantConditional = (touched, disabled, errors) => {
    return touched && !disabled && errors ? '' : undefined;
};

class DayMonthYearInput extends React.Component {
    static generateValue(dRef, mRef, yRef) {
        const formattedValue = {
            day: dRef,
            month: mRef,
            year: yRef,
            value: null
        };

        // This next line of code is covered by the EnterDOBPostcode.spec.jsx file
        /* istanbul ignore next */
        if (formattedValue.day || formattedValue.month || formattedValue.year) {
            const yyyy = formattedValue.year;
            const mm = DayMonthYearInput.twoDigit(formattedValue.month);
            const dd = DayMonthYearInput.twoDigit(formattedValue.day);
            const dateString = `${yyyy}-${mm}-${dd}`;

            formattedValue.value = dataValidators.isValidDate(dateString)
                ? new Date(`${dateString}T00:00:00.000Z`)
                : 'invalid-date';
        }

        return formattedValue;
    }

    static getUpdatedValue(newValue) {
        const defaults = {
            day: '',
            month: '',
            year: '',
            value: null
        };
        return {
            value: {
                ...defaults,
                ...newValue
            }
        };
    }

    static twoDigit = currValue => {
        if (currValue.toString().length < 2) return `0${currValue}`;
        return currValue;
    };

    constructor(props) {
        super(props);
        this.state = {
            ...DayMonthYearInput.getUpdatedValue(props.value)
        };
    }

    render() {
        const {
            name,
            errors,
            setFieldValue,
            setFieldTouched,
            touched,
            autoFocus,
            value,
            disabled
        } = this.props;

        const inputProps = {
            backgroundColor: disabledInputColor(disabled),
            opacity: '1',
            // eslint-disable-next-line
            disabled: disabled
        };

        const updateValue = e => {
            const newValue = DayMonthYearInput.generateValue(
                e.day,
                e.month,
                e.year
            );
            this.setState(DayMonthYearInput.getUpdatedValue(newValue));

            // eslint-disable-next-line no-param-reassign
            e.target = {
                ...e.target,
                name,
                value: newValue.value,
                forceDebounce: true
            };

            setFieldValue(name, newValue);
        };

        return (
            <Flex mt={[0, 0, 0]} mb={0}>
                <Box
                    mx={[0, 'lg']}
                    pl={0}
                    width={['auto', 'auto']}
                    margin={['auto', 'auto']}
                    position="relative"
                    flexGrow={1}
                    fontSize={[
                        FONT_SIZES.TEXT_SMALL_MOBILE,
                        FONT_SIZES.TEXT_MEDIUM_DESKTOP
                    ]}
                    lineHeight={LINE_HEIGHTS.TEXT_SMALL_DESKTOP}
                    fontWeight={FONT_WEIGHTS.RG}
                    color={COLORS.capitalOne.blue750}
                >
                    <DateInput
                        name="dob"
                        value={value}
                        onChange={updateValue}
                        onBlur={() => setFieldTouched(name)}
                        error={variantConditional(
                            touched,
                            disabled,
                            errors[name]
                        )}
                        dayProps={inputProps}
                        monthProps={inputProps}
                        yearProps={inputProps}
                        disabled={disabled}
                        autoFocus={autoFocus}
                        showInformationalIcon={false}
                    />
                </Box>
            </Flex>
        );
    }
}

DayMonthYearInput.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    touched: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.object.isRequired,
    autoFocus: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired
};

export default DayMonthYearInput;
