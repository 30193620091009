import {
    Box,
    THEME_CONSTANTS,
    Paragraph,
    Button,
    Link,
    InfoIcon,
    Flex,
    useBrand,
    THEME_UTILITIES,
    Text
} from '@cof/plastic-components';
import React from 'react';
import PropTypes from 'prop-types';
import text from '../../text';
import { infoIconColour } from '../../utils/styleChanges';

const { FONT_WEIGHTS } = THEME_CONSTANTS;
const { useColor } = THEME_UTILITIES;

const FormFooter = ({ chain, module, search }) => {
    const boxColour = useColor({
        capitalOne: 'global.grey50',
        postOffice: 'postOffice.grey50',
        ASOS: 'global.grey50'
    });
    const brand = useBrand();
    return (
        <>
            {chain !== 'login' || module !== 'LDAP1' ? null : (
                <>
                    <Box
                        width="100%"
                        backgroundColor={boxColour}
                        py="md"
                        px={['md', 'lg', 'lg']}
                        data-qa-id="form-footer"
                    >
                        <Box textAlign="center">
                            <Text
                                size="medium"
                                pb={[0, 0, 0]}
                                fontWeight={FONT_WEIGHTS.MD}
                            >
                                {
                                    text.EnterSimpleCredentials.registerAccount
                                        .title
                                }
                            </Text>
                            <Box display="flex" justifyContent="center">
                                <Button
                                    width={['100%', '256px', '256px']}
                                    variant="secondary"
                                    as="a"
                                    href={`/register${search}`}
                                    mt={['md', 'md', 'md']}
                                    data-qa-id="register-button"
                                >
                                    {
                                        text.EnterSimpleCredentials
                                            .registerAccount.button.label
                                    }
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Flex mt="sm" flexDirection="row">
                        <InfoIcon
                            size={['5.2rem', '3rem']}
                            color={infoIconColour(brand)}
                            mr="xs"
                        />
                        <Paragraph data-qa-id="privacy-policy">
                            {text.EnterSimpleCredentials.privacyPolicy.text}
                            <Link
                                href={text.EnterSimpleCredentials.privacyPolicy.url(
                                    brand
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {
                                    text.EnterSimpleCredentials.privacyPolicy
                                        .urlText
                                }
                            </Link>
                        </Paragraph>
                    </Flex>
                </>
            )}
        </>
    );
};

FormFooter.propTypes = {
    chain: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
};

export default FormFooter;
