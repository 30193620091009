import React from 'react';
import {
    Flex,
    THEME_CONSTANTS,
    useBrand,
    SmartphoneIcon,
    PhoneIcon
} from '@cof/plastic-components';
import PropTypes from 'prop-types';
import {
    greyPhoneHeaderLines,
    phoneIconColour
} from '../../utils/styleChanges';

const { COLORS } = THEME_CONSTANTS;

const PhoneHeader = ({ variant }) => {
    const brand = useBrand();
    return (
        <Flex
            data-qa-id={`otp-get-${variant}-phone-header`}
            width={['auto', '100%', '100%']}
            style={{
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <hr
                style={{
                    backgroundColor: greyPhoneHeaderLines(brand),
                    width: '50%',
                    border: '0',
                    height: '0.3rem'
                }}
            />
            {variant === 'mobile' && (
                <Flex data-qa-id="otp-get-mobile-icon">
                    <SmartphoneIcon
                        width="4rem"
                        height="4rem"
                        backgroundColor={COLORS.global.white}
                        color={phoneIconColour(brand)}
                    />
                </Flex>
            )}
            {variant === 'landline' && (
                <Flex data-qa-id="otp-get-landline-icon">
                    <PhoneIcon
                        width="4rem"
                        height="4rem"
                        backgroundColor={COLORS.global.white}
                        color={phoneIconColour(brand)}
                    />
                </Flex>
            )}
            <hr
                style={{
                    backgroundColor: greyPhoneHeaderLines(brand),
                    width: '50%',
                    border: '0',
                    height: '0.3rem'
                }}
            />
        </Flex>
    );
};

PhoneHeader.propTypes = {
    variant: PropTypes.string.isRequired
};

export default PhoneHeader;
