import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Flex,
    Text,
    useBrand,
    THEME_CONSTANTS,
    Button
} from '@cof/plastic-components';
import appendBrandParam from '../../utils/appendBrandParam';
import text from '../../text';

const RetrieveUsername = ({ username }) => {
    const brand = useBrand();
    const { FONT_SIZES } = THEME_CONSTANTS;

    return (
        <Flex
            minHeight={['200px', '300px']}
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
        >
            <Text
                data-qa-id="retrieve-username-heading"
                as="h3"
                mt="0 !important"
                mb="xs"
                pt={['xs', '0', 'xs']}
                fontSize={[
                    FONT_SIZES.TEXT_LARGE_MOBILE,
                    FONT_SIZES.TEXT_LARGE_DESKTOP
                ]}
            >
                {text.RetrieveUsername.heading}
            </Text>
            <Text
                data-qa-id="retrieve-username-text"
                fontSize={[
                    FONT_SIZES.TEXT_LARGE_MOBILE,
                    FONT_SIZES.TEXT_LARGE_DESKTOP
                ]}
            >
                {text.RetrieveUsername.text}
            </Text>
            <Text
                data-qa-id="retrieve-username-name"
                mb="sm"
                fontWeight="semiBold"
                fontSize={[
                    FONT_SIZES.TEXT_LARGE_MOBILE,
                    FONT_SIZES.TEXT_LARGE_DESKTOP
                ]}
            >
                {username}
            </Text>
            <Box mb="sm">
                <Button
                    id="continueButton"
                    as="a"
                    href={appendBrandParam(
                        brand,
                        text.RetrieveUsername.loginButton.url
                    )}
                    data-qa-id="continue-button"
                    minWidth={['100%', '256px']}
                >
                    {text.RetrieveUsername.loginButton.text}
                </Button>
            </Box>
        </Flex>
    );
};

RetrieveUsername.propTypes = {
    username: PropTypes.string.isRequired
};

export default RetrieveUsername;
