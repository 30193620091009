import axios from 'axios';
import config from '@cof/ukweb-config/lib/clientConfig';
import { getEncryptionKey, setCSRF } from '../../utils/utils';
import text from '../../text';

/**
 * Maps the form response to the next module
 * @returns {*|string}      The type parameter used for routing.
 */

const CSRFInitApi = ({ setLoading }, setAdditionalData) => {
    const { scheme, host, port } = config.get('orchestration');
    const search = window.location.search ? `${window.location.search}&` : '?';
    const queryParams = `${search}service=${window.location.pathname.replace(
        '/',
        ''
    )}`;
    return axios
        .get(`${scheme}://${host}:${port}/submit${queryParams}`, {
            withCredentials: true,
            validateStatus: status => status >= 200 && status <= 302
        })
        .then(res => {
            setCSRF(res);
            const encryptionKey = getEncryptionKey(res);
            if (encryptionKey) {
                setAdditionalData({
                    cpa: { encryptionKey }
                });
            }
            setLoading(false);
        })
        .catch(() => {
            setLoading(false);
            setAdditionalData({
                hasError: true,
                errorMessage: text.SharedText.globalErrorText.submitError
            });
        });
};

export default CSRFInitApi;
