import { setCSRF } from '../../utils/utils';

/**
 * Maps the form response to the next module
 * @returns {*|string}      The type parameter used for routing.
 */

const enterDOBPostcodeApi = ({ setCurrentModule, setAdditionalData }, res) => {
    setCSRF(res);
    const formSchemaResponse = res.data.formSchema[0];
    setAdditionalData({
        cpa: '',
        phoneNumberData: formSchemaResponse ? formSchemaResponse.choices : [],
        sessionToken: res.data.sessionToken
    });
    setCurrentModule(res);
};

export default enterDOBPostcodeApi;
