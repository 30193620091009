const getGlobalErrorStage = chain => {
    const defaultModules = {
        login: 'LDAP1',
        register: 'UsernameModule2',
        forgotPassword: 'UsernameModule2',
        forgotUsername: 'ForgotUsernameModule1'
    };

    return defaultModules[chain];
};

const getFirstStage = chain => {
    const defaultModules = {
        login: 'LDAP1',
        register: 'UsernameModule2',
        forgotPassword: 'UsernameModule2',
        forgotUsername: 'ForgotUsernameModule1'
    };

    return defaultModules[chain];
};

export { getFirstStage, getGlobalErrorStage };
