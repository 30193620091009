import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Flex,
    Input,
    Label,
    THEME_CONSTANTS,
    useBrand
} from '@cof/plastic-components';
import dataValidators from '@cof/data-validators';
import {
    acceptOnlyNumbersInput,
    acceptOnlyNumericPasteEvent
} from '../../utils/utils';
import {
    errorBorderColor,
    borderBottomStyle,
    marginBottomStyle,
    variantConditional,
    disabledInputColor
} from '../../utils/styleChanges';
import text from '../../text';
import ErrorHelperText from '../../_molecules/ErrorHelperText';

const { FONT_WEIGHTS } = THEME_CONSTANTS;

const EnterActivationCodeForm = ({
    onSubmit,
    disabled,
    setDisabled,
    isFinalAttempt,
    onGetAnotherCode
}) => {
    const brand = useBrand();
    const [inputValue, setInputValue] = useState('');

    return (
        <Formik
            initialValues={{ authField1: inputValue, authField2: '0' }}
            validate={values => {
                const errors = {};
                if (!values.authField1) {
                    errors.authField1 = text.EnterOTPPage.errorMessage;
                } else if (!dataValidators.isValidOtp(values.authField1)) {
                    errors.authField1 = text.EnterOTPPage.errorMessage;
                }
                return errors;
            }}
            onSubmit={(values, formik) => {
                setDisabled(true);
                setInputValue(values.authField1);
                onSubmit(
                    {
                        authField1: values.authField1,
                        authField2: values.authField2
                    },
                    formik
                );
            }}
            enableReinitialize
        >
            {({
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                values
            }) => (
                <form onSubmit={handleSubmit} data-qa-id="otp-input-form">
                    <Box position="relative">
                        <Box>
                            <Label
                                data-qa-id="otp-input-label"
                                variant="dark"
                                htmlFor="authField1"
                                fontWeight={FONT_WEIGHTS.RG}
                                mt="0 !important"
                            >
                                {text.EnterOTPPage.heading}
                            </Label>
                        </Box>
                        <Input
                            maxLength={6}
                            type="text"
                            inputMode="numeric"
                            id="authField1"
                            value={values.authField1}
                            onPaste={e => acceptOnlyNumericPasteEvent(e)}
                            onBlur={handleBlur}
                            onChange={e => {
                                acceptOnlyNumbersInput(e, setInputValue, () => {
                                    handleChange(e);
                                });
                            }}
                            variant={variantConditional(
                                touched.authField1,
                                disabled,
                                errors.authField1
                            )}
                            data-qa-id="otp-input-textbox"
                            disabled={disabled}
                            style={{
                                borderColor: errorBorderColor(
                                    errors.authField1,
                                    touched.authField1,
                                    disabled
                                ),
                                backgroundColor: disabledInputColor(disabled),
                                opacity: '1',
                                minWidth: '100%'
                            }}
                            autoComplete="one-time-code"
                            autoFocus
                            mb={[null, 'xs']}
                        />
                        {touched.authField1 &&
                            !disabled &&
                            errors.authField1 && (
                                <ErrorHelperText
                                    qaId="otp-input-error-message-container"
                                    id="otp-input-error"
                                >
                                    {errors.authField1}
                                </ErrorHelperText>
                            )}
                    </Box>
                    <Box
                        flexGrow={[1, 0]}
                        style={{
                            borderBottom: borderBottomStyle(
                                isFinalAttempt,
                                brand
                            )
                        }}
                        display="flex"
                        justifyContent="center"
                    >
                        <Button
                            id="submitButton"
                            type="submit"
                            width={['100%', '256px', '256px']}
                            mt="md"
                            data-qa-id="otp-input-form-submit"
                            mb={marginBottomStyle(isFinalAttempt)}
                            disabled={disabled}
                            loading={disabled}
                        >
                            {text.EnterOTPPage.submit}
                        </Button>
                    </Box>
                    {!isFinalAttempt && (
                        <Flex mt="md">
                            <Box>
                                <Button
                                    data-qa-id="otp-input-last-page-button"
                                    variant="textLinkInline"
                                    onClick={onGetAnotherCode}
                                >
                                    {text.EnterOTPPage.anotherCodeLink}
                                </Button>
                            </Box>
                        </Flex>
                    )}
                </form>
            )}
        </Formik>
    );
};

EnterActivationCodeForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    setDisabled: PropTypes.func.isRequired,
    onGetAnotherCode: PropTypes.func.isRequired,
    isFinalAttempt: PropTypes.bool.isRequired
};

export default EnterActivationCodeForm;
