/**
 * Cryptographically generate 5-byte random string and encode as 10 hex chars
 * @returns {string}
 */
export const getRandomPaddingString = len =>
    Array.from(window.crypto.getRandomValues(new Uint8Array(len)))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');

/**
 * Convert string of hex chars to array of bytes
 * e.g.
 * hex string: "141234ab" ->
 * split into groups of 2: ["14", "12", "34", "ab"] ->
 * each value converted from hex chars to int representing a byte: [20, 18, 52, 171]
 * @param hex
 */
export const hexToBytes = hex => {
    // split into groups of 2
    const hexBytes = hex.match(/..?/g);
    const buffer = new ArrayBuffer(hex.length / 2);
    const bufferView = new Uint8Array(buffer);
    hexBytes.forEach((hexByte, i) => {
        bufferView[i] = parseInt(hexByte, 16);
    });
    return buffer;
};

/**
 * The PINBlock is being generated in ISO format 1 (https://www.eftlab.co.uk/knowledge-base/261-complete-list-of-pin-blocks-in-payments/#ISO-1).
 * It's a 16 character block with the following values:
 *
 * INDEX: 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16
 * CHAR : 1 4 { PIN } {   10 random hex chars  }
 *
 * @param data
 * @returns {string}
 */
export const generatePinBlock = data => {
    const format = '1';
    const pinLen = '4';
    const hexPadding = getRandomPaddingString(5);

    return `${format}${pinLen}${data}${hexPadding}`;
};

/**
 * Converts string to UTF-8 character-encoded array buffer
 * @param str
 * @returns {ArrayBuffer}
 */
export const stringToArrayBuffer = str => {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i += 1) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
};

export const importPublicKey = encodedPublicKey => {
    const binaryDerString = window.atob(encodedPublicKey);
    const binaryDer = stringToArrayBuffer(binaryDerString);
    return window.crypto.subtle.importKey(
        'spki',
        binaryDer,
        {
            name: 'RSA-OAEP',
            hash: 'SHA-256'
        },
        true,
        ['encrypt']
    );
};

const createBase64Cipher = text =>
    window.btoa(String.fromCharCode.apply(null, new Uint8Array(text)));

/**
 * Takes the request body and encrypts the PIN using the RSA-OAEP algorithm
 * @param body
 * @param publicKey
 * @returns {Promise<{authField1: string}>}
 */
const encrypt = async (body, publicKey) => {
    const importedPublicKey = await importPublicKey(publicKey);

    // build pin block according to spec
    const pinBlock = generatePinBlock(body.authField1);
    const encodedPinBlock = hexToBytes(pinBlock);

    // encrypt
    const encryptedPinBlockArrayBuffer = await window.crypto.subtle.encrypt(
        {
            name: 'RSA-OAEP'
        },
        importedPublicKey,
        encodedPinBlock
    );

    return {
        authField1: createBase64Cipher(encryptedPinBlockArrayBuffer)
    };
};

/**
 * Encrypts a string value with JWK using the RSA-OAEP algorithm
 * @param authField
 * @param jsonWebKey
 * @returns {Promise<string>}
 */
export const encryptWithJwk = async (authField, jsonWebKey) => {
    const importedPublicKey = await window.crypto.subtle.importKey(
        'jwk',
        jsonWebKey,
        {
            name: 'RSA-OAEP',
            hash: 'SHA-256'
        },
        true,
        ['encrypt']
    );
    const encodedAuthField = stringToArrayBuffer(authField);

    // encrypt
    const encryptedAuthFieldArrayBuffer = await window.crypto.subtle.encrypt(
        {
            name: 'RSA-OAEP'
        },
        importedPublicKey,
        encodedAuthField
    );

    return createBase64Cipher(encryptedAuthFieldArrayBuffer);
};

export default encrypt;
