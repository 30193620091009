import React from 'react';
import ForgotUsernameForm from '../../_organisms/ForgotUsername';
import forgotUsernameApi from '../ForgotUsername/forgotUsernameApi';
import RetrieveUsername from '../../_organisms/RetrieveUsername';
import commonModules from './commonModulesConfig';

const modules = {
    ForgotUsernameModule1: {
        heading: 'I’ve forgotten my username',
        subHeading: () =>
            'So we’re certain it’s you please confirm the following details',
        qaId: 'forgot-username',
        pageForm: props => (
            <ForgotUsernameForm
                onSubmit={props.onSubmit}
                locationSearch={window.location.search}
                {...props}
            />
        ),
        onSubmit: props => {
            window.setTimeout(() => forgotUsernameApi(props), 500);
        }
    },
    RetrieveUsernameModule: {
        heading: '',
        subHeading: () => null,
        qaId: 'retrieve-username',
        pageForm: props => (
            <RetrieveUsername
                locationSearch={window.location.search}
                {...props}
            />
        )
    },
    ...commonModules
};

export default modules;
