import React from 'react';
import EnterSimpleCredentials from '../../_organisms/EnterSimpleCredentials';
import enterSimpleCredentialsApi from '../EnterSimpleCredentials/enterSimpleCredentialsApi';
import orchHandler from './orchHandler';
import commonModules from './commonModulesConfig';

const modules = {
    LDAP1: {
        heading: 'Sign in to your account',
        subHeading: () => '',
        qaId: 'simple-credentials',
        pageForm: props => (
            <EnterSimpleCredentials
                onSubmit={props.onSubmit}
                locationSearch={window.location.search}
                {...props}
            />
        ),
        onSubmit: props => {
            window.setTimeout(
                () => orchHandler(props, enterSimpleCredentialsApi),
                500
            );
        }
    },
    ...commonModules
};

export default modules;
