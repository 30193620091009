// At least 1 lowercase alpha, 1 uppercase alpha, 1 number. Length between 8 and 128 spaces and special characters
const isValidPassword = stringVal =>
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,128}$/.test(stringVal);

// tests that string has between 8 and 128 characters
const passwordRangeIsValid = stringVal => /^.{8,128}$/.test(stringVal);

// Validates password has at least 1 uppercase and 1 lowercase letter
const passwordHasUpperLowerLetters = stringVal =>
    /^(?=.*[A-Z])(?=.*[a-z]).{2,128}$/.test(stringVal);

// Validates password has at least 1 number
const passwordContainsANumber = stringVal =>
    /^(?=.*[\d]).{1,128}$/.test(stringVal);

const validatePasswordInput = (validationSchema, values, text) => {
    if (!validationSchema.isValidSync(values)) return {};

    const errors = {};
    const { authField2, authField3 } = values;
    if (
        authField2.length > 0 &&
        authField3.length > 0 &&
        authField2 !== authField3
    ) {
        errors.authField3 = text;
    }
    return errors;
};

export {
    isValidPassword,
    passwordRangeIsValid,
    passwordHasUpperLowerLetters,
    passwordContainsANumber,
    validatePasswordInput
};
