export default {
    UsernamePage: {
        usernameInput: {
            usernameFAQ: {
                summary: 'Help me with this step',
                details:
                    'Your username isn’t case sensitive and it could be your email address.\n\nTo move to the next step, we need to confirm your username, date of birth and postcode. Enter any of these details incorrectly and you’ll need to try again, starting with your username. For security reasons, we can’t tell you which details are wrong. After too many failed attempts, we’ll lock your account temporarily.\n\nThe postcode we need is the one on your credit card statement – usually your home address.'
            }
        },
        fieldLabel: 'Username',
        helpLink: {
            url: '/forgot-username',
            text: "I don't know my username"
        }
    },
    DOBPostcodePage: {
        dobPostcodeInput: {
            dobPostcodeFAQ: {
                summary: 'Help me with this step',
                details:
                    'Enter your date of birth in dd mm yyyy format. For example, if your birthday is 12 June 1980, enter 12 06 1980. The postcode we need is the one on your credit card statement – usually your home address.\n\nTo move to the next step, we need to confirm your username, date of birth and postcode. Enter any of these details incorrectly and you’ll need to try again, starting with your username. For security reasons, we can’t tell you which details are wrong. After too many failed attempts, we’ll lock your account temporarily.'
            }
        }
    },
    RequestOTPPage: {
        numbers: {
            phoneFAQ: {
                summary: "That's not my number",
                detailsAbove:
                    'Phone number wrong or not showing? You can update it or add it in your app.',
                detailsBelow: 'Or call us if you prefer:'
            }
        },
        submit: 'Get code',
        phoneTypes: {
            mobile: "We'll text your code to you.",
            landline:
                "We'll ring your landline and read your code to you. This call is automated."
        },
        help: {
            underButton:
                'We only show you part of your phone number for your security.'
        },
        noPhoneNumber: `Oops, we don’t have your phone number. Please give us a call now on [0344 481 0884](tel:+443444810884){data-qa-id=no-number-info-link}{style="color: #CC2427;"} to add your mobile or landline to your account.`
    },
    EnterOTPPage: {
        heading: 'Enter activation code',
        submit: 'Validate code',
        anotherCodeLink: 'Get another code',
        errorMessage: 'Please enter a 6-digit code'
    },
    EnterPasswordPage: {
        passwordInput: {
            passwordFAQ: {
                summary: 'Password tips',
                details:
                    'Choose a password you’ve not used before to sign in to your Capital One account. It can’t be the same as your username. And for security reasons, we can’t accept weak passwords, such as ‘Password1’ or ‘abc1234’. When you’re creating a new password, make it memorable enough that you don’t have to write it down. But avoid using information that others could guess or might know – like your birthday, child’s name, phone number or street name.'
            }
        },
        errorText: {
            authField2Invalid: 'Your password doesn’t meet our requirements',
            authField3Invalid: 'Please confirm your password',
            nonMatching: 'Your passwords entered don’t match'
        },
        submit: 'Sign in',
        passwordLabel: 'Password',
        confirmLabel: 'Confirm password',
        validators: {
            uppercaseLowercase: 'Contains an uppercase and lowercase letter',
            length: 'Contains at least 8 characters',
            number: 'Contains a number'
        }
    },
    EnterSimpleCredentials: {
        submit: 'Sign in',
        usernameInput: {
            fieldLabel: 'Username (this could be your email address)',
            errorText: 'Please enter a valid username'
        },
        passwordInput: {
            fieldLabel: 'Password',
            errorText: 'Please enter a valid password'
        },
        forgotUsername: {
            url: '/forgot-username',
            text: 'I’ve forgotten my username'
        },
        forgotPhrase: {
            url: '/forgot-password',
            text: 'I’ve forgotten my password'
        },
        ManageDiffCard: {
            summary: 'Manage a different card',
            details: `Each of your cards has its own username and password. If you’re having trouble signing in, it could be because you’ve not [activated the new version](${`${window.location.origin}/register${window.location.search}`}){data-qa-id=not-yet-registered-link}{style="color: #CC2427;"} of online banking for that card.`
        },
        registerAccount: {
            title: 'Register for your online account',
            button: {
                label: 'Register'
            }
        },
        privacyPolicy: {
            url: brand => {
                const brandMapping = {
                    capitalOne:
                        'https://www.capitalone.co.uk/web/privacy-statement.jsf',
                    postOffice:
                        'https://postoffice.capitalone.co.uk/partnerships/privacy-statement.jsf',
                    ASOS: 'https://capitalone.co.uk/asos/privacy-policy'
                };

                return brandMapping[brand] || brandMapping.capitalOne;
            },
            urlText: 'Privacy Policy',
            text: 'We use information from your device to help keep your account safe. To find out more, read our '
        },
        successError: {
            text: "That's all done! You can now sign in with your password."
        }
    },
    ForgotUsername: {
        authField1: {
            fieldLabel: 'Your 16-digit card number',
            requiredErrorText: 'Your 16-digit card number is required',
            validationErrorText: 'This is not a valid card number'
        },
        authField2: {
            fieldLabel: 'Date of birth',
            requiredErrorText: 'Date of birth is required',
            validationErrorText: 'This is not a valid date of birth'
        },
        authField3: {
            fieldLabel: 'Postcode',
            requiredErrorText: 'Postcode is required',
            validationErrorText: 'This is not a valid postcode'
        }
    },
    RetrieveUsername: {
        heading: 'Thanks for confirming your details',
        text: 'Your username is:',
        loginButton: {
            url: '/login',
            text: 'Continue to sign in'
        }
    },
    SharedText: {
        errorText: 'Please try again',
        submit: 'Continue',
        globalErrorText: {
            somethingWentWrong: 'Oops! Something went wrong. Please try again.',
            sorryPleaseCall:
                'Sorry, we don’t recognise these details. Please try again or call us on [0344 481 0884](tel:+443444810884){data-qa-id=error-number-info-link}{style="color: #CC2427;"}.',
            oneMoreTry:
                'You have one more attempt to confirm your details before your account is locked temporarily. Get help with this step below.',
            alreadyRegistered: `Oops! It looks like you’ve already activated your new online experience. [Go straight to sign in](${`${window.location.origin}/login${window.location.search}`}){data-qa-id=already-registered-signin-link}{style="color: #CC2427;"}.`,
            notYetRegistered: `We cannot reset your password because you haven’t activated your new and improved account experience. [Please start activation.](${`${window.location.origin}/register${window.location.search}`}){data-qa-id=not-yet-registered-link}{style="color: #CC2427;"}`,
            codeLastAttempt:
                'You have one more attempt to validate your code before you’ll need to start again.',
            codeNotRecognised:
                'Sorry, we don’t recognise this code. Please try again.',
            expiredCode:
                'Sorry, this code has expired. Get another code below.',
            tooManyIncorrectCodes:
                'Sorry, you entered your code incorrectly too many times. Please start again.',
            weakPassword:
                'The password you’ve chosen is not strong enough or you may have used it before. See password tips below for help and try again.',
            sessionTimeout: 'Your session has timed out. Please try again.',
            loginCredentialsInvalid: `Sorry, we don’t recognise these details. A recent update to your online account may still need activating before you can sign in. [Do this now](${`${window.location.origin}/register${window.location.search}`}){data-qa-id=not-yet-registered-link}{style="color: #CC2427;"}, or get help below if you’ve forgotten your details.`,
            forgotUsernameError: `Sorry, we couldn’t match these details, please try again.`,
            submitError:
                'This service is unavailable at this time, please try again later.'
        }
    },
    ErrorPageText: {
        Lockout: {
            heading: 'Sorry, we’ve locked your account',
            summary: 'Incorrect information entered too many times',
            details:
                'Your online account is locked temporarily. You can still use your card, but you’ll need to wait a while to try signing in again.'
        },
        AuthAppError: {
            heading: 'Oops! Something went wrong',
            summary: 'Trying to sign in or register?',
            details: `It looks like there was an issue in trying to complete your request.\n\r Let's give it another go by [trying again](${`${window.location.origin}/login${window.location.search}`}).\n\n &nbsp;\n\n**Trying to complete an Open Banking authorisation?**\n\r Please go back to the third-party with whom you wish to share your Capital One information and try making the request again.\n\r`
        }
    }
};
