import React from 'react';
import { InfoPanel, Markdown, THEME_CONSTANTS } from '@cof/plastic-components';
import text from '../../text';

const { COLORS } = THEME_CONSTANTS;

const ActivationCodeNoNumbers = () => {
    return (
        <InfoPanel type="alert" data-qa-id="otp-no-number-info">
            <Markdown
                color={COLORS.capitalOne.red550}
                data-qa-id="no-number-info-text"
                size="small"
                allowedAttributes={['style', 'data-qa-id']}
            >
                {text.RequestOTPPage.noPhoneNumber}
            </Markdown>
        </InfoPanel>
    );
};

export default ActivationCodeNoNumbers;
