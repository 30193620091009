import React from 'react';
import PropTypes from 'prop-types';
import {
    HelperText,
    InlineFlex,
    THEME_CONSTANTS
} from '@cof/plastic-components';

const { FONT_SIZES, FONT_WEIGHTS } = THEME_CONSTANTS;

const ErrorHelperText = ({ qaId, id, children, ...props }) => {
    return (
        <HelperText
            {...props}
            data-qa-id={qaId}
            variant="error"
            id={id}
            fontWeight={FONT_WEIGHTS.MD}
        >
            <InlineFlex mt={[0]}>
                <p
                    id={`${id}-message`}
                    data-qa-id={`${id}-message`}
                    fontSize={[
                        FONT_SIZES.TEXT_SMALL_MOBILE,
                        FONT_SIZES.TEXT_MEDIUM_DESKTOP
                    ]}
                >
                    {children}
                </p>
            </InlineFlex>
        </HelperText>
    );
};

ErrorHelperText.propTypes = {
    qaId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    children: PropTypes.string
};

ErrorHelperText.defaultProps = {
    children: null
};

export default ErrorHelperText;
