import React from 'react';
import { Heading, THEME_CONSTANTS } from '@cof/plastic-components';

const { LINE_HEIGHTS, FONT_WEIGHTS, TEXT_SIZES } = THEME_CONSTANTS;

const AccessibleH2 = ({ ...props }) => {
    return (
        <Heading
            {...props}
            size={TEXT_SIZES.MEDIUM}
            lineHeight={[
                LINE_HEIGHTS.HEADING_SMALL_MOBILE,
                LINE_HEIGHTS.HEADING_SMALL_DESKTOP
            ]}
            fontWeight={FONT_WEIGHTS.SB}
            level="1"
            pb={0}
            bordered={false}
        />
    );
};

export default AccessibleH2;
