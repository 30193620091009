import { setCSRF, getErrorForWarningText } from '../../utils/utils';
/**
 * Maps the form response to the next module
 * @returns {*|string}      The type parameter used for routing.
 */

const choosePasswordApi = (
    { setCurrentModule, setAdditionalData, additionalData },
    res
) => {
    setCSRF(res);
    setCurrentModule(res);
    setAdditionalData({
        sessionToken: additionalData.sessionToken,
        warningCode: res.data?.warningCode,
        errorMessage: getErrorForWarningText(res),
        hasError: !!res.data?.warningCode
    });
};

export default choosePasswordApi;
