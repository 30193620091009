import axios from 'axios';
import config from '@cof/ukweb-config/lib/clientConfig';
import { setCSRF, makeRequestHeaders, errorHandling } from '../../utils/utils';

const buildFormData = body => {
    const formData = {};
    const extraFields = {};
    Object.keys(body).forEach(key => {
        if (key.includes('authField')) {
            formData[key] = body[key];
        } else {
            extraFields[key] = body[key];
        }
    });
    return { formData, extraFields };
};

const orchHandler = async (
    {
        navigate,
        brand,
        setCurrentModule,
        setAdditionalData,
        chain,
        currentModule,
        additionalData,
        ...body
    },
    responseHandler
) => {
    const { scheme, host, port } = config.get('orchestration');
    const { formData, extraFields } = buildFormData(body);
    let response;
    try {
        response = await axios.post(
            `${scheme}://${host}:${port}/submit`,
            {
                formData,
                stage: currentModule,
                sessionToken: additionalData.sessionToken,
                chain,
                ...extraFields
            },
            {
                withCredentials: true,
                headers: makeRequestHeaders()
            }
        );
        if (response.data.authenticated) {
            setCSRF(response);
            setCurrentModule(response);
        } else {
            responseHandler(
                {
                    navigate,
                    brand,
                    setCurrentModule,
                    setAdditionalData,
                    chain,
                    currentModule,
                    additionalData
                },
                response
            );
        }
    } catch (error) {
        errorHandling(
            error,
            setCurrentModule,
            setAdditionalData,
            additionalData,
            navigate,
            brand,
            chain
        );
    }
};

export default orchHandler;
