import text from '../text';
import { getGlobalErrorStage } from './getDefaultStage';

const errorCodeMap = (error, chain) => {
    const defaultModule = getGlobalErrorStage(chain);

    return (
        {
            LOGIN_CREDENTIALS_INVALID: {
                message:
                    text.SharedText.globalErrorText.loginCredentialsInvalid,
                stage: defaultModule
            },

            DATA_INVALID: {
                message:
                    text.SharedText.globalErrorText.loginCredentialsInvalid,
                stage: defaultModule
            },
            GLOBAL_LOCKOUT: {
                message: text.SharedText.globalErrorText.oneMoreTry,
                stage: defaultModule
            },
            DETAILS_UNRECOGNISED: {
                message: text.SharedText.globalErrorText.sorryPleaseCall,
                stage: defaultModule
            },
            ALREADY_REGISTERED: {
                message: text.SharedText.globalErrorText.alreadyRegistered,
                stage: defaultModule
            },
            NOT_YET_REGISTERED: {
                message: text.SharedText.globalErrorText.notYetRegistered,
                stage: defaultModule
            },
            REQUEST_TIMEOUT: {
                message: text.SharedText.globalErrorText.sessionTimeout,
                stage: defaultModule
            },
            SESSION_TIMEOUT: {
                message: text.SharedText.globalErrorText.sessionTimeout,
                stage: defaultModule
            },
            OTP_FINAL_ATTEMPT: {
                message: text.SharedText.globalErrorText.codeLastAttempt,
                stage: 'OtpModule3'
            },
            OTP_INVALID_CODE: {
                message: text.SharedText.globalErrorText.codeNotRecognised,
                stage: 'OtpModule3'
            },
            OTP_EXPIRED_CODE: {
                message: text.SharedText.globalErrorText.expiredCode,
                stage: 'OtpModule3'
            },
            OTP_LIMIT: {
                message: text.SharedText.globalErrorText.tooManyIncorrectCodes,
                stage: defaultModule
            },
            INVALID_PASSWORD: {
                message: text.SharedText.globalErrorText.weakPassword,
                stage: 'PasswordResetModule2'
            },
            FORGOT_USERNAME_ERROR: {
                message: text.SharedText.globalErrorText.forgotUsernameError,
                stage: defaultModule
            }
        }[error] || {
            message: text.SharedText.globalErrorText.somethingWentWrong,
            stage: defaultModule
        }
    );
};

export default errorCodeMap;
