import { setCSRF } from '../../utils/utils';

/**
 * Maps the form response to the next module
 * @returns {*|string}      The type parameter used for routing.
 */

const enterSimpleCredentialsApi = (
    { setCurrentModule, setAdditionalData },
    res
) => {
    // find out where to get current modle from adn other things
    setCSRF(res);
    const formSchemaResponse = res.data.formSchema[0];
    setAdditionalData({
        cpa: '',
        phoneNumberData: formSchemaResponse ? formSchemaResponse.choices : [],
        sessionToken: res.data.sessionToken
    });
    setCurrentModule(res);
};

export default enterSimpleCredentialsApi;
