/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
    AccordionItem,
    Box,
    Button,
    Input,
    Label,
    Markdown,
    THEME_CONSTANTS
} from '@cof/plastic-components';
import dataValidators from '@cof/data-validators';
import {
    disabledInputColor,
    errorBorderColor,
    variantConditional
} from '../../utils/styleChanges';
import text from '../../text';
import DayMonthYearInput from '../../_molecules/DayMonthYearInput';
import ErrorHelperText from '../../_molecules/ErrorHelperText';

const { COLORS, FONT_SIZES, FONT_WEIGHTS, LINE_HEIGHTS } = THEME_CONSTANTS;

const checkDate = date => {
    const dateFrom = new Date('1900-01-01T00:00:00Z');
    const dateTo = new Date();

    return date > dateFrom && date < dateTo;
};

const EnterDOBPostcodeForm = ({ onSubmit, disabled, setDisabled }) => {
    const [formValues, setFormValues] = useState({
        authField1: { day: '', month: '', year: '' },
        authField2: ''
    });
    useEffect(() => {
        document.getElementById('dob-day').focus();
    }, []);

    return (
        <Formik
            initialValues={formValues}
            validate={values => {
                const errors = {};
                if (
                    !checkDate(values.authField1 && values.authField1.value) ||
                    !values.authField1 ||
                    values.authField1.value === null
                ) {
                    errors.authField1 = text.SharedText.errorText;
                }
                if (
                    !dataValidators.isValidInternationalPostcode(
                        values.authField2
                    )
                ) {
                    errors.authField2 = text.SharedText.errorText;
                }
                return errors;
            }}
            onSubmit={(values, formik) => {
                const yyyyMmDdDate =
                    values.authField1.value instanceof Date &&
                    values.authField1.value.toISOString().substring(0, 10);
                setDisabled(true);
                setFormValues(values);
                onSubmit(
                    {
                        authField1: yyyyMmDdDate,
                        authField2: values.authField2
                    },
                    formik
                );
            }}
            enableReinitialize
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched
            }) => (
                <form onSubmit={handleSubmit} data-qa-id="dob-postcode-form">
                    <Box>
                        <Box mb={['xs']}>
                            <Label
                                data-qa-id="dob-label"
                                variant="dark"
                                htmlFor="dob-day"
                                fontWeight={FONT_WEIGHTS.RG}
                                mt="0 !important"
                                pt={[
                                    '0.8rem !important',
                                    '0rem !important',
                                    '0.8rem !important'
                                ]}
                            >
                                Date of birth
                            </Label>
                            <DayMonthYearInput
                                name="authField1"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errors={errors}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                touched={touched?.authField1}
                                value={values.authField1}
                                disabled={disabled}
                                autoFocus
                            />
                        </Box>
                        {touched.authField1 &&
                            !disabled &&
                            errors.authField1 && (
                                <ErrorHelperText
                                    qaId="dob-input-error-change"
                                    id="dob-error"
                                >
                                    {errors.authField1}
                                </ErrorHelperText>
                            )}
                    </Box>
                    <Box
                        position="relative"
                        fontSize={[
                            FONT_SIZES.TEXT_SMALL_MOBILE,
                            FONT_SIZES.TEXT_MEDIUM_DESKTOP
                        ]}
                        lineHeight={LINE_HEIGHTS.TEXT_SMALL_DESKTOP}
                        fontWeight={FONT_WEIGHTS.RG}
                        color={COLORS.capitalOne.blue750}
                    >
                        <Label
                            data-qa-id="postcode-label"
                            variant="dark"
                            htmlFor="authField2"
                            fontWeight={FONT_WEIGHTS.RG}
                        >
                            Postcode
                        </Label>
                        <Input
                            id="authField2"
                            data-qa-id="postcode-input"
                            disabled={disabled}
                            style={{
                                borderColor: errorBorderColor(
                                    errors.authField2,
                                    touched.authField2,
                                    disabled
                                ),
                                backgroundColor: disabledInputColor(disabled),
                                opacity: '1',
                                minWidth: '100%'
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.authField2}
                            variant={variantConditional(
                                touched.authField2,
                                disabled,
                                errors.authField2
                            )}
                            mb={[null, 'xs']}
                        />
                        {touched.authField2 &&
                            !disabled &&
                            errors.authField2 && (
                                <ErrorHelperText
                                    qaId="postcode-input-error-change"
                                    id="postcode-error"
                                >
                                    {errors.authField2}
                                </ErrorHelperText>
                            )}
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Button
                            id="submitButton"
                            type="submit"
                            width={['100%', '256px', '256px']}
                            mt="md"
                            data-qa-id="dob-postcode-form-submit"
                            mb="sm"
                            disabled={disabled}
                            loading={disabled}
                        >
                            {text.SharedText.submit}
                        </Button>
                    </Box>
                    <Box>
                        <AccordionItem
                            heading={
                                text.DOBPostcodePage.dobPostcodeInput
                                    .dobPostcodeFAQ.summary
                            }
                            mt={['xs', null, 'sm']}
                            data-qa-id="dob-postcode-input-accordion"
                        >
                            <Markdown data-qa-id="dob-postcode-input-accordion-text">
                                {
                                    text.DOBPostcodePage.dobPostcodeInput
                                        .dobPostcodeFAQ.details
                                }
                            </Markdown>
                        </AccordionItem>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

EnterDOBPostcodeForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    setDisabled: PropTypes.func.isRequired
};

export default EnterDOBPostcodeForm;
