import xss from 'xss';
import axios from 'axios';
import config from '@cof/ukweb-config/lib/clientConfig';
import { BRANDS } from '@cof/plastic-components';

const wsBrandMap = {
    [BRANDS.POST_OFFICE]: 'postoffice'
};

const redirectWithToken = async (encodedUri, idsToken, chain, brand) => {
    const queryParams = new URLSearchParams(encodedUri);
    const { url: loginUrl } = config.get('wsLoginUrl');
    try {
        const goto = new URL(queryParams.get('goto'));
        const response = await axios.post(
            `${goto.origin}${goto.pathname}`,
            {
                idsToken,
                responseType: 'code',
                clientId: goto.searchParams.get('client_id'),
                scope: goto.searchParams.get('scope'),
                redirectUri: goto.searchParams.get('redirect_uri')
            },
            { withCredentials: true }
        );
        global.location.href = xss(response.data.callbackURL);
    } catch {
        if (chain === 'login') {
            const authAppErrorUrl = new URL(`${loginUrl}authapp-error`);
            if (typeof brand !== 'undefined' && brand !== BRANDS.CAPITAL_ONE) {
                authAppErrorUrl.searchParams.append(
                    'channel',
                    wsBrandMap[brand]
                );
            }
            global.location.href = authAppErrorUrl.toString();
        } else {
            queryParams.append('successMessage', 'true');
            window.location = `/login?${decodeURIComponent(
                queryParams.toString()
            )}`;
        }
    }
};

export default redirectWithToken;
