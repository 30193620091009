/* eslint-disable complexity */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Flex,
    Input,
    Label,
    THEME_CONSTANTS
} from '@cof/plastic-components';
import dataValidators from '@cof/data-validators';
import DayMonthYearInput from '../../_molecules/DayMonthYearInput';
import {
    disabledInputColor,
    errorBorderColor,
    variantConditional
} from '../../utils/styleChanges';
import text from '../../text';
import ErrorHelperText from '../../_molecules/ErrorHelperText';

const { FONT_SIZES, FONT_WEIGHTS, LINE_HEIGHTS } = THEME_CONSTANTS;

const StyledBox = ({ ...props }) => (
    <Box
        {...props}
        position="relative"
        fontSize={[
            FONT_SIZES.TEXT_SMALL_MOBILE,
            FONT_SIZES.TEXT_MEDIUM_DESKTOP
        ]}
        lineHeight={LINE_HEIGHTS.TEXT_SMALL_DESKTOP}
    />
);

const ForgotUsernameForm = ({ onSubmit, disabled, setDisabled }) => {
    const [formValues, setFormValues] = useState({
        authField1: '',
        authField2: { day: '', month: '', year: '' },
        authField3: ''
    });
    const formValidation = values => {
        const checkDate = date => {
            const dateFrom = new Date('1900-01-01T00:00:00Z');
            const dateTo = new Date();

            return date > dateFrom && date < dateTo;
        };
        const errors = {};

        if (!dataValidators.isValidCardNumber(values.authField1)) {
            errors.authField1 =
                text.ForgotUsername.authField1.validationErrorText;
        }
        if (!checkDate(values.authField2?.value)) {
            errors.authField2 =
                text.ForgotUsername.authField2.validationErrorText;
        }
        if (!dataValidators.isValidInternationalPostcode(values.authField3)) {
            errors.authField3 =
                text.ForgotUsername.authField3.validationErrorText;
        }
        if (!values.authField1) {
            errors.authField1 =
                text.ForgotUsername.authField1.requiredErrorText;
        }
        if (!values.authField2?.value) {
            errors.authField2 =
                text.ForgotUsername.authField2.requiredErrorText;
        }
        if (!values.authField3) {
            errors.authField3 =
                text.ForgotUsername.authField3.requiredErrorText;
        }
        return errors;
    };

    const formSubmit = (values, formik) => {
        const yyyyMmDdDate =
            values.authField2.value instanceof Date &&
            values.authField2.value.toISOString().substring(0, 10);
        setDisabled(true);
        setFormValues(values);
        onSubmit(
            {
                authField1: values.authField1,
                authField2: yyyyMmDdDate,
                authField3: values.authField3
            },
            formik
        );
    };
    return (
        <Formik
            initialValues={formValues}
            enableReinitialize
            validate={formValidation}
            onSubmit={formSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched
            }) => (
                <form onSubmit={handleSubmit} data-qa-id="forgot-username-form">
                    <StyledBox>
                        <Label
                            data-qa-id="pan-input-label"
                            htmlFor="authField1"
                            mt="0 !important"
                            fontWeight={FONT_WEIGHTS.RG}
                        >
                            {text.ForgotUsername.authField1.fieldLabel}
                        </Label>
                        <Input
                            id="authField1"
                            name="authField1"
                            data-qa-id="pan-input"
                            disabled={disabled}
                            style={{
                                borderColor: errorBorderColor(
                                    errors.authField1,
                                    touched.authField1,
                                    disabled
                                ),
                                backgroundColor: disabledInputColor(disabled),
                                opacity: '1',
                                minWidth: '100%'
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            autoFocus
                            value={values.authField1}
                            variant={variantConditional(
                                touched.authField1,
                                disabled,
                                errors.authField1
                            )}
                        />
                        {touched.authField1 &&
                            !disabled &&
                            errors.authField1 && (
                                <ErrorHelperText
                                    qaId="pan-input-error-change"
                                    id="pan-error"
                                >
                                    {errors.authField1}
                                </ErrorHelperText>
                            )}
                    </StyledBox>
                    <StyledBox>
                        <Label
                            data-qa-id="dob-input-label"
                            htmlFor="dob-day"
                            mt="0 !important"
                            pt="sm"
                            fontWeight={FONT_WEIGHTS.RG}
                        >
                            {text.ForgotUsername.authField2.fieldLabel}
                        </Label>
                        <DayMonthYearInput
                            name="authField2"
                            pt="0 !important"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            touched={touched.authField2}
                            value={values.authField2}
                            disabled={disabled}
                        />
                        {touched.authField2 &&
                            !disabled &&
                            errors.authField2 && (
                                <ErrorHelperText
                                    qaId="dob-input-error-change"
                                    id="dob-error"
                                >
                                    {errors.authField2}
                                </ErrorHelperText>
                            )}
                    </StyledBox>
                    <StyledBox>
                        <Label
                            data-qa-id="postcode-input-label"
                            htmlFor="authField3"
                            fontWeight={FONT_WEIGHTS.RG}
                            mt="0 !important"
                            pt="sm"
                        >
                            {text.ForgotUsername.authField3.fieldLabel}
                        </Label>
                        <Input
                            id="authField3"
                            name="authField3"
                            data-qa-id="postcode-input"
                            disabled={disabled}
                            style={{
                                borderColor: errorBorderColor(
                                    errors.authField3,
                                    touched.authField3,
                                    disabled
                                ),
                                backgroundColor: disabledInputColor(disabled),
                                opacity: '1',
                                minWidth: '100%'
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.authField3}
                            variant={variantConditional(
                                touched.authField3,
                                disabled,
                                errors.authField3
                            )}
                        />
                        {touched.authField3 &&
                            !disabled &&
                            errors.authField3 && (
                                <ErrorHelperText
                                    qaId="postcode-input-error-change"
                                    id="postcode-error"
                                >
                                    {errors.authField3}
                                </ErrorHelperText>
                            )}
                    </StyledBox>
                    <Flex justifyContent="center">
                        <Button
                            id="submitButton"
                            data-qa-id="forgot-username-form-submit"
                            type="submit"
                            mt="md"
                            disabled={disabled}
                            loading={disabled}
                            minWidth={['100%', '256px']}
                        >
                            {text.SharedText.submit}
                        </Button>
                    </Flex>
                </form>
            )}
        </Formik>
    );
};

ForgotUsernameForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    setDisabled: PropTypes.func.isRequired
};

export default ForgotUsernameForm;
