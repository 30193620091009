import axios from 'axios';
import config from '@cof/ukweb-config/lib/clientConfig';
import { encryptWithJwk } from '../../utils/encrypt';
import {
    makeRequestHeaders,
    setCSRF,
    errorHandling,
    getErrorForWarningText
} from '../../utils/utils';

/**
 * Maps the form response to the next module
 * @returns {*|string}      The type parameter used for routing.
 */

const forgotUsernameApi = ({
    navigate,
    brand,
    setCurrentModule,
    setAdditionalData,
    currentModule,
    additionalData,
    chain,
    ...body
}) => {
    return encryptWithJwk(body.authField1, additionalData?.cpa?.encryptionKey)
        .then(secureAuthField1 => {
            const { scheme, host, port } = config.get('orchestration');

            return axios
                .post(
                    `${scheme}://${host}:${port}/submit`,

                    {
                        formData: { ...body, authField1: secureAuthField1 },
                        stage: currentModule,
                        chain
                    },
                    {
                        withCredentials: true,
                        headers: makeRequestHeaders()
                    }
                )
                .then(res => {
                    setCSRF(res);
                    setCurrentModule(res);
                    setAdditionalData({
                        username: res.data?.username,
                        errorMessage: getErrorForWarningText(res),
                        hasError: !!res.data?.warningCode
                    });
                });
        })
        .catch(error => {
            errorHandling(
                error,
                setCurrentModule,
                setAdditionalData,
                additionalData,
                navigate,
                brand,
                chain
            );
        });
};

export default forgotUsernameApi;
