import React from 'react';
import {
    useBrand,
    PageRowFullHeight,
    Heading,
    Paragraph,
    Box
} from '@cof/plastic-components';
import text from '../../text';
import {
    themedBackgroundColor,
    themedFontColor
} from '../../utils/styleChanges';

const LockoutPage = () => {
    const brand = useBrand();
    return (
        <PageRowFullHeight
            data-qa-id="error-lockout-content"
            wrapperBg={themedBackgroundColor(brand)}
            py={['md', 'lg']}
        >
            <Heading
                level="1"
                textAlign="left"
                mt={['sm', null]}
                color={themedFontColor(brand)}
                data-qa-id="error-lockout-heading"
                lineHeight={['1.0', '1.0']}
            >
                {text.ErrorPageText.Lockout.heading}
            </Heading>
            <Paragraph
                size="large"
                color={themedFontColor(brand)}
                mt="xs"
                data-qa-id="error-lockout-summary"
            >
                {text.ErrorPageText.Lockout.summary}
            </Paragraph>
            <Box width={['80%', 1 / 2, '52%']} mt="sm">
                <Paragraph
                    size="medium"
                    color={themedFontColor(brand)}
                    data-qa-id="error-lockout-detail"
                >
                    {text.ErrorPageText.Lockout.details}
                </Paragraph>
            </Box>
        </PageRowFullHeight>
    );
};

export default LockoutPage;
