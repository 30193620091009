import { useCallback, useEffect } from 'react';
import { v4 } from 'uuid';
import config from '@cof/ukweb-config/lib/clientConfig';

const baseConfig = () => ({
    essx: config.get('edv.essx'),
    esto: 'org-nqdyt59r-krygt0tb-rb4bk2r3f-aarsscb8',
    ggow: v4(),
    mosc: v4(),
    mwelseq: 1,
    reed: false,
    loco: false,
    time: Date.now(),
    ccsp: true,
    dacc: true
});

const journeyConfig = {
    login: {
        mwel: 'PrimaryLogin',
        mwelsub: 'UNP',
        sanf: JSON.stringify({ '#SimpleCredentialsForm': 'submit' })
    }
};

const useEDV = ({ chain, clientError, serverError }) => {
    const stopWebSDK = () => {
        window.sessionStorage.removeItem('cx');
    };

    const startWebSDK = useCallback(() => {
        if (!Object.keys(journeyConfig).includes(chain)) {
            return null;
        }

        const edvConfig = {
            ...baseConfig(),
            ...journeyConfig[chain]
        };
        return window.sessionStorage.setItem('cx', JSON.stringify(edvConfig));
    }, [chain]);

    // only run on mount
    useEffect(() => {
        startWebSDK();
        window.addEventListener('unload', stopWebSDK);

        return () => {
            window.removeEventListener('unload', stopWebSDK);
            stopWebSDK();
        };
    }, [startWebSDK]);

    // only run when client side form state is invalid (e.g. no u/pw)
    useEffect(() => {
        if (clientError > 0) {
            startWebSDK();
        }
    }, [clientError, startWebSDK]);

    // only run when there was an error response from orch (e.g. wrong u/pw)
    useEffect(() => {
        if (serverError) {
            startWebSDK();
        }
    }, [serverError, startWebSDK]);
};

export default useEDV;
