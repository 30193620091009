import React from 'react';
import EnterUsername from '../../_organisms/EnterUsername';
import enterUsernameApi from '../EnterUsername/enterUsernameApi';
import EnterDOBPostcode from '../../_organisms/EnterDOBPostcode';
import enterDOBPostcodeApi from '../EnterDOBPostcode/enterDOBPostcodeApi';
import ChoosePasswordForm from '../../_organisms/ChoosePassword';
import choosePasswordApi from '../ChoosePassword/choosePasswordApi';
import orchHandler from './orchHandler';
import commonModules from './commonModulesConfig';

const modules = {
    UsernameModule2: {
        heading: 'Enter your username',
        subHeading: () =>
            'We send your username in the post when you first open your account. It could be your email address.',
        qaId: 'username',
        pageForm: props => (
            <EnterUsername onSubmit={props.onSubmit} {...props} />
        ),
        onSubmit: props => {
            window.setTimeout(() => orchHandler(props, enterUsernameApi), 500);
        }
    },
    DetailsCheckModule2: {
        heading: 'Enter your details',
        subHeading: () => '',
        qaId: 'dob-postcode',
        pageForm: props => (
            <EnterDOBPostcode onSubmit={props.onSubmit} {...props} />
        ),
        onSubmit: props => {
            window.setTimeout(
                () => orchHandler(props, enterDOBPostcodeApi),
                500
            );
        }
    },
    PasswordResetModule2: {
        heading: 'Choose a password',
        subHeading: () => '',
        qaId: 'password',
        pageForm: props => <ChoosePasswordForm {...props} />,
        onSubmit: props => {
            window.setTimeout(() => orchHandler(props, choosePasswordApi), 500);
        }
    },
    ...commonModules
};

export default modules;
