import React from 'react';
import EnterOTP from '../../_organisms/GetActivationCode';
import getActivationCodeApi from '../GetActivationCode/getActivationCodeApi';
import GetActivationCodeNoNumbers from '../../_organisms/GetActivationCodeNoNumbers';
import EnterActivationCodeForm from '../../_organisms/EnterActivationCode';
import enterActivationCodeApi from '../EnterActivationCode/enterActivationCodeApi';
import orchHandler from './orchHandler';

const modules = {
    OtpModule2: {
        // request code
        heading: 'Get activation code',
        subHeading: () =>
            "We need to send you a 6-digit activation code now. Choose how you'll receive it.",
        qaId: 'otp-get',
        pageForm: props => <EnterOTP {...props} />,
        onSubmit: props => {
            window.setTimeout(
                () => orchHandler(props, getActivationCodeApi),
                500
            );
        }
    },
    OtpModule3: {
        // enter code
        heading: 'Enter your code',
        subHeading: () =>
            'Please enter your 6-digit activation code within 10 minutes of receiving it.',
        qaId: 'enter-otp',
        pageForm: props => <EnterActivationCodeForm {...props} />,
        onSubmit: props => {
            window.setTimeout(
                () => orchHandler(props, enterActivationCodeApi),
                500
            );
        }
    },
    OtpModule5: {
        // no numbers on file
        heading: 'Get activation code',
        subHeading: () =>
            "We need to send you a 6-digit activation code now. Choose how you'll receive it.",
        qaId: 'otp-no-number',
        pageForm: props => <GetActivationCodeNoNumbers {...props} />
    }
};

export default modules;
