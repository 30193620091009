import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
    THEME_CONSTANTS,
    useBrand,
    PageRowTopOverlay,
    Flex,
    Box,
    Markdown,
    PageRow,
    LoadingSpinnerPortal,
    InfoPanel
} from '@cof/plastic-components';

import AccessibleH2 from '../../_molecules/Heading';
import regChainConfig from './regChainConfig';
import loginChainConfig from './loginChainConfig';
import forgotUsernameChainConfig from './forgotUsernameChainConfig';
import { organismProps } from '../../utils/utils';
import enterActivationCodeApi from '../EnterActivationCode/enterActivationCodeApi';
import csrfInitApi from '../CSRFInit/CSRFInitApi';
import {
    borderBottomStyle,
    themedHeadingColor
} from '../../utils/styleChanges';
import redirectWithToken from './redirectWithToken';
import FormFooter from '../../_molecules/FormFooter';
import orchHandler from './orchHandler';
import { getFirstStage } from '../../utils/getDefaultStage';

const { COLORS, TEXT_SIZES } = THEME_CONSTANTS;

const loadingSpinnerConditionals = (loading, parameterLoad) => {
    return loading || !parameterLoad;
};

export const isSuccessfulAuthenticationResponse = ({ data }) =>
    !!(data && data.authenticated);

const StageContainer = ({ chain, parameterLoad }) => {
    const navigate = useNavigate();
    const brand = useBrand();
    const [currentModule, setCurrentModule] = useState(getFirstStage(chain)); // default module state will need changing when /init is built
    const [additionalData, setAdditionalData] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        csrfInitApi({ setLoading }, setAdditionalData);
    }, []);
    const [disabled, setDisabled] = useState(false);
    const setCurrentModuleWrapper = response => {
        // Dispatches an authentication successful action and acts according to the response.
        // If authentication is complete, hand off to the authorization flow, otherwise show the next stage.
        /* c8 ignore start */
        if (isSuccessfulAuthenticationResponse(response)) {
            redirectWithToken(
                window.location.search,
                response.data.tokenId,
                chain,
                brand
            );
            /* c8 ignore end */
        } else {
            setCurrentModule(
                typeof response === 'string' ? response : response.data.stage
            );
            setDisabled(false);
        }
    };

    const chainLookup = {
        register: regChainConfig[currentModule],
        forgotPassword: regChainConfig[currentModule],
        login: loginChainConfig[currentModule],
        forgotUsername: forgotUsernameChainConfig[currentModule]
    };

    const stage = chainLookup[chain];
    const { heading, subHeading, qaId, pageForm: Page, onSubmit } = stage;

    const submissionFunction = ({ ...args }, { resetForm }) => {
        const returnValue = onSubmit({
            ...args,
            navigate,
            brand,
            setCurrentModule: setCurrentModuleWrapper,
            setAdditionalData,
            currentModule,
            additionalData,
            chain
        });
        resetForm();
        return returnValue;
    };

    const organismPropsLoader = organismProps(
        currentModule,
        additionalData,
        submissionFunction,
        navigate,
        brand,
        setCurrentModuleWrapper,
        setAdditionalData,
        enterActivationCodeApi,
        disabled,
        setDisabled,
        orchHandler
    );

    const subheadingText = subHeading(additionalData.cpa);
    const maxContainerWidth = '672px';

    return (
        <>
            <PageRowTopOverlay
                px={['sm', 'xl']}
                cutoutHeight="-20rem"
                maxWidth={maxContainerWidth}
                mb="0"
                bg="transparent"
            />
            <Flex
                width="100%"
                justifyContent="center"
                mb={['lg', 'xl']}
                mt={['-8rem', '-1.6rem']}
            >
                <Box
                    width={['95%', '100%', 'auto']}
                    maxWidth={maxContainerWidth}
                    mx={['sm']}
                >
                    <PageRowTopOverlay
                        style={{
                            border: borderBottomStyle(false, brand),
                            borderBottom: '0',
                            margin: '0 !important'
                        }}
                        pt={['sm', 'lg']}
                        pb={['xs', '-0.8rem']}
                    >
                        <AccessibleH2
                            color={themedHeadingColor(brand)}
                            pb={0}
                            textAlign={['center']}
                            data-qa-id={`${qaId}-input-heading`}
                            id={`${qaId}-input-heading`}
                        >
                            {heading}
                        </AccessibleH2>
                        {subheadingText && (
                            <Markdown
                                textAlign={['left']}
                                size={TEXT_SIZES.LARGE}
                                data-qa-id={`${qaId}-input-lead`}
                                mt={['sm', 'sm']}
                            >
                                {subheadingText}
                            </Markdown>
                        )}
                    </PageRowTopOverlay>
                    {additionalData.hasError && (
                        <PageRow
                            style={{
                                border: borderBottomStyle(false, brand),
                                borderTop: '0',
                                borderBottom: '0'
                            }}
                            maxWidth={maxContainerWidth}
                            pt={[0, 0, 0]}
                            pb={['xs', 'xs', 'xs']}
                            px={[0, 0, 0]}
                            backgroundColor={COLORS.global.white}
                        >
                            <InfoPanel
                                data-qa-id="error-info-panel"
                                type="alert"
                                mx={['sm', 'lg']}
                                pb="xs"
                            >
                                <Markdown
                                    color={COLORS.capitalOne.red550}
                                    data-qa-id="error-info-text"
                                    allowedAttributes={['style', 'data-qa-id']}
                                >
                                    {additionalData.errorMessage}
                                </Markdown>
                            </InfoPanel>
                        </PageRow>
                    )}
                    <PageRow
                        style={{
                            border: borderBottomStyle(false, brand),
                            borderTop: '0'
                        }}
                        maxWidth={maxContainerWidth}
                        minWidth={[null, null, maxContainerWidth]}
                        pt={[0, 0, 0]}
                        pb={['md', 'md', 'lg']}
                        px={['sm', 'lg', 'lg']}
                        backgroundColor={COLORS.global.white}
                    >
                        <Page {...organismPropsLoader} />
                    </PageRow>
                    <FormFooter
                        chain={chain}
                        module={currentModule}
                        search={window.location.search}
                    />
                </Box>
            </Flex>
            {loadingSpinnerConditionals(loading, parameterLoad) && (
                <LoadingSpinnerPortal
                    domElementId="app_spinner"
                    data-qa-id="form-loading-spinner"
                    bgOverlay="solid"
                    zIndex={3}
                />
            )}
        </>
    );
};

StageContainer.defaultProps = {
    parameterLoad: true
};

StageContainer.propTypes = {
    chain: PropTypes.string.isRequired,
    parameterLoad: PropTypes.bool
};

export default StageContainer;
