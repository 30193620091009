import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import {
    globalStyles,
    styleReset,
    ApplicationLayout,
    QueryStringThemeProvider,
    BRANDS,
    BrandedFooter,
    useBrand
} from '@cof/plastic-components';
import '@cof/plastic-components/fonts.css';
import { v4 } from 'uuid';
import config from '@cof/ukweb-config/lib/clientConfig';
import Header, { BorderedHeader } from '../_molecules/Header';
import StageContainer from '../ecosystems/StageContainer';
import LockoutPage from '../ecosystems/ErrorPages/lockoutPage';
import { authOutboundURL, lookupChannel } from '../utils/utils';
import constants from '../utils/constants';
import AuthAppErrorPage from '../ecosystems/ErrorPages/authAppErrorPage';

const GlobalStyle = createGlobalStyle`
    ${styleReset}
    ${globalStyles}
    html {
        height: 100%;
        overflow-x: hidden;
    }

    /* stylelint-disable selector-max-type, selector-max-id, selector-max-specificity */
    body, #root {
        height: 100%;
    }
`;

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const Redirect = () => {
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const channel = lookupChannel(queryParams.get('channel'));
        window.location = `/login?channel=${channel}`;
    }, []);
};

const footerVariant = {
    [BRANDS.ASOS]: 'lightSecondary',
    [BRANDS.POST_OFFICE]: 'light',
    [BRANDS.CAPITAL_ONE]: 'light'
};

const Router = () => {
    const brand = useBrand();
    const [parametersLoading, setParametersLoading] = useState(false);
    useEffect(() => {
        // Check for goTo presence
        if (
            typeof window.location?.search?.includes === 'function' &&
            !window.location.search.includes('goto') &&
            constants[window.location.pathname]
        ) {
            const queryParams = new URLSearchParams(window.location.search);
            // redirect to /outbound as gotoURL is missing
            const service = constants[window.location.pathname];

            // Customer who have bookmarked the login page will get stuck - send them via the outbound endpoint to
            // pick up the gotoUrl so this doesn't happen
            window.location = authOutboundURL(
                service,
                lookupChannel(queryParams.get('channel'))
            );
        }
        if (typeof window.location?.search?.includes === 'function') {
            setParametersLoading(window.location?.search?.includes('goto'));
        } else {
            setParametersLoading(true);
        }
    }, []);

    return (
        <Routes>
            <Route
                key="register"
                exact
                path="/register"
                element={
                    <ApplicationLayout
                        Header={Header}
                        Footer={() => (
                            <BrandedFooter variant={footerVariant[brand]} />
                        )}
                    >
                        <StageContainer
                            chain="register"
                            parameterLoad={parametersLoading}
                        />
                    </ApplicationLayout>
                }
            />
            <Route
                key="forgot-password"
                exact
                path="/forgot-password"
                element={
                    <ApplicationLayout
                        Header={Header}
                        Footer={() => (
                            <BrandedFooter variant={footerVariant[brand]} />
                        )}
                    >
                        <StageContainer
                            chain="forgotPassword"
                            parameterLoad={parametersLoading}
                        />
                    </ApplicationLayout>
                }
            />
            <Route
                key="forgot-username"
                exact
                path="/forgot-username"
                element={
                    <ApplicationLayout Header={Header}>
                        <StageContainer chain="forgotUsername" />
                    </ApplicationLayout>
                }
            />
            <Route
                key="authapp-error"
                exact
                path="/authapp-error"
                element={
                    <ApplicationLayout
                        Header={BorderedHeader}
                        Footer={() => (
                            <BrandedFooter variant={footerVariant[brand]} />
                        )}
                    >
                        <AuthAppErrorPage />
                    </ApplicationLayout>
                }
            />
            <Route
                key="lockout"
                exact
                path="/lockout"
                element={
                    <ApplicationLayout
                        Header={BorderedHeader}
                        Footer={() => (
                            <BrandedFooter variant={footerVariant[brand]} />
                        )}
                    >
                        <LockoutPage />
                    </ApplicationLayout>
                }
            />
            <Route
                key="login"
                exact
                path="/login"
                element={
                    <ApplicationLayout
                        Header={Header}
                        Footer={() => (
                            <BrandedFooter variant={footerVariant[brand]} />
                        )}
                    >
                        <StageContainer
                            chain="login"
                            parameterLoad={parametersLoading}
                        />
                    </ApplicationLayout>
                }
            />
            <Route path="*" element={<Redirect />} />
        </Routes>
    );
};

const generateEDVScript = () => {
    const script = document.createElement('script');
    script.nonce = v4();
    script.src = config.get('edv.sdkUrl');
    script.defer = true;
    document.body.appendChild(script);
};

const App = () => (
    <QueryStringThemeProvider
        queryStringParam="channel"
        defaultBrand={BRANDS.CAPITAL_ONE}
    >
        <GlobalStyle />
        <ScrollToTop />
        <Router />
        <script>{generateEDVScript()}</script>
    </QueryStringThemeProvider>
);

export default App;
