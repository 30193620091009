import { setCSRF } from '../../utils/utils';

/**
 * Maps the form response to the next module
 * @returns {*|string}      The type parameter used for routing.
 */

const getActivationCodeApi = ({ setCurrentModule, setAdditionalData }, res) => {
    setCSRF(res);
    setCurrentModule(res);
    setAdditionalData({
        cpa: '',
        phoneNumberData: [],
        sessionToken: res.data.sessionToken
    });
};

export default getActivationCodeApi;
