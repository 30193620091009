/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
    AccordionItem,
    Box,
    Flex,
    Input,
    Label,
    Link,
    Markdown,
    THEME_CONSTANTS,
    useBrand,
    LockIcon,
    ThumbsUpIcon,
    PageRow,
    InfoPanel,
    Button
} from '@cof/plastic-components';
import dataValidators from '@cof/data-validators';
import {
    errorBorderColor,
    variantConditional,
    disabledInputColor
} from '../../utils/styleChanges';
import text from '../../text';
import useEDV from './useEDV';
import appendBrandParam from '../../utils/appendBrandParam';
import ErrorHelperText from '../../_molecules/ErrorHelperText';

const { COLORS, FONT_WEIGHTS } = THEME_CONSTANTS;

const EnterSimpleCredentialsForm = ({
    onSubmit,
    disabled,
    setDisabled,
    hasError,
    locationSearch
}) => {
    const brand = useBrand();
    const [clientError, setClientError] = useState(0);
    const [serverError, setServerError] = useState(false);
    const [formValues, setFormValues] = useState({
        authField1: '',
        authField2: ''
    });
    const search = new URLSearchParams(locationSearch);

    useEffect(() => {
        if (hasError && !disabled) {
            setServerError(true);
        }
    }, [disabled, hasError]);

    useEDV({
        chain: 'login',
        clientError,
        serverError
    });

    const onClick = (touched, errors, values) => {
        const formNotTouched = Object.keys(touched).length === 0;
        const errorsInForm = Object.keys(errors).length > 0;
        const inputValuesExist = Object.keys(values).some(
            key => values[key].length > 0
        );
        if ((formNotTouched && !inputValuesExist) || errorsInForm) {
            setClientError(clientError + 1);
        }
        setServerError(false);
    };

    const submitWithTimeout = (values, formik) => {
        const { ggow, mosc } = JSON.parse(sessionStorage.getItem('cx'));
        const submissionPayload = {
            formData: values,
            ids: {
                ggow,
                mosc
            }
        };
        let timeoutRef;
        // react testing library fails when re-renders at the window level happen, so no way to actually test this function runs
        /* c8 ignore start */
        const onCollectionCompleted = () => {
            clearTimeout(timeoutRef);
            onSubmit(submissionPayload, formik);
        };
        /* c8 ignore end */
        timeoutRef = setTimeout(() => {
            window.removeEventListener(
                'websdkCollectionCompleted',
                onCollectionCompleted
            );
            onSubmit(submissionPayload, formik);
        }, 100);
        window.addEventListener(
            'websdkCollectionCompleted',
            onCollectionCompleted,
            { once: true }
        );
    };

    const renderSuccessMessage = () =>
        window.location?.search?.includes('successMessage=true') && (
            <PageRow
                py={['xs', 'xs', 'xs']}
                px={[0, 0, 0]}
                backgroundColor={COLORS.global.white}
            >
                <InfoPanel
                    Icon={ThumbsUpIcon}
                    pb="xs"
                    data-qa-id="error-info-panel-success-message"
                >
                    <Markdown
                        color={COLORS.capitalOne.blue750}
                        data-qa-id="error-info-text-success-message"
                        allowedAttributes={['style', 'data-qa-id']}
                    >
                        {text.EnterSimpleCredentials.successError.text}
                    </Markdown>
                </InfoPanel>
            </PageRow>
        );

    return (
        <Formik
            initialValues={formValues}
            validate={values => {
                const errors = {};
                if (!values.authField1) {
                    errors.authField1 =
                        text.EnterSimpleCredentials.usernameInput.errorText;
                }
                if (!values.authField2) {
                    errors.authField2 =
                        text.EnterSimpleCredentials.passwordInput.errorText;
                }
                if (!dataValidators.isValidUsername(values.authField1)) {
                    errors.authField1 =
                        text.EnterSimpleCredentials.usernameInput.errorText;
                }
                return errors;
            }}
            onSubmit={(values, formik) => {
                setDisabled(true);
                setFormValues(values);
                submitWithTimeout(
                    {
                        authField1: values.authField1,
                        authField2: values.authField2
                    },
                    formik
                );
            }}
            enableReinitialize
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit
            }) => (
                <>
                    {renderSuccessMessage()}
                    <form
                        onSubmit={handleSubmit}
                        data-qa-id="simple-credentials-form"
                        id="SimpleCredentialsForm"
                    >
                        <Box mx={[0, 0]} position="relative">
                            <div>
                                <Label
                                    data-qa-id="username-label"
                                    variant="dark"
                                    htmlFor="authField1"
                                    fontWeight={FONT_WEIGHTS.RG}
                                    mt="0 !important"
                                    pt={[
                                        '0.8rem !important',
                                        '0rem !important',
                                        '0.8rem !important'
                                    ]}
                                >
                                    {
                                        text.EnterSimpleCredentials
                                            .usernameInput.fieldLabel
                                    }
                                </Label>
                            </div>
                            <Input
                                type="text"
                                id="authField1"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                data-qa-id="username-input"
                                disabled={disabled}
                                style={{
                                    borderColor: errorBorderColor(
                                        errors.authField1,
                                        touched.authField1,
                                        disabled
                                    ),
                                    backgroundColor:
                                        disabledInputColor(disabled),
                                    minWidth: '100%'
                                }}
                                value={values.authField1}
                                variant={variantConditional(
                                    touched.authField1,
                                    disabled,
                                    errors.authField1
                                )}
                                className="cs-username"
                            />
                            {touched.authField1 &&
                                !disabled &&
                                errors.authField1 && (
                                    <ErrorHelperText
                                        qaId="username-input-error-change"
                                        id="username-error"
                                    >
                                        {errors.authField1}
                                    </ErrorHelperText>
                                )}

                            <AccordionItem
                                heading={
                                    text.EnterSimpleCredentials.ManageDiffCard
                                        .summary
                                }
                                mt={['xs']}
                                data-qa-id="username-input-accordion"
                                px={[0, 0, 0]}
                            >
                                <Markdown data-qa-id="username-input-accordion-text">
                                    {
                                        text.EnterSimpleCredentials
                                            .ManageDiffCard.details
                                    }
                                </Markdown>
                            </AccordionItem>
                        </Box>
                        <Box mx={[0, 0]} position="relative">
                            <div>
                                <Label
                                    data-qa-id="password-label"
                                    variant="dark"
                                    htmlFor="authField2"
                                    fontWeight={FONT_WEIGHTS.RG}
                                    mt="0 !important"
                                    pt={[
                                        '0.8rem !important',
                                        '0rem !important',
                                        '0.8rem !important'
                                    ]}
                                >
                                    {
                                        text.EnterSimpleCredentials
                                            .passwordInput.fieldLabel
                                    }
                                </Label>
                            </div>
                            <Input
                                type="password"
                                id="authField2"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                data-qa-id="password-input"
                                disabled={disabled}
                                style={{
                                    borderColor: errorBorderColor(
                                        errors.authField2,
                                        touched.authField2,
                                        disabled
                                    ),
                                    backgroundColor:
                                        disabledInputColor(disabled),
                                    minWidth: '100%'
                                }}
                                value={values.authField2}
                                variant={variantConditional(
                                    touched.authField2,
                                    disabled,
                                    errors.authField2
                                )}
                                className="cs-password"
                            />
                            {touched.authField2 &&
                                !disabled &&
                                errors.authField2 && (
                                    <ErrorHelperText
                                        qaId="password-input-error-change"
                                        id="password-error"
                                    >
                                        {errors.authField2}
                                    </ErrorHelperText>
                                )}
                        </Box>

                        <Box
                            flexGrow={[1, 0]}
                            display="flex"
                            justifyContent="center"
                        >
                            <Button
                                id="submitButton"
                                type="submit"
                                width={['100%', '256px', '256px']}
                                mt="md"
                                mb="lg"
                                data-qa-id="simple-credentials-form-submit"
                                disabled={disabled}
                                loading={disabled}
                                onClick={() => onClick(touched, errors, values)}
                            >
                                <LockIcon
                                    verticalAlign="middle"
                                    display="inline-block"
                                    style={{
                                        marginTop: '-0.5rem',
                                        paddingRight: '0.5rem',
                                        width: '2.2rem',
                                        height: '2.2rem'
                                    }}
                                />
                                {text.EnterSimpleCredentials.submit}
                            </Button>
                        </Box>
                        <Flex flexDirection="column">
                            <Box mb="xs">
                                <Link
                                    href={appendBrandParam(
                                        brand,
                                        text.EnterSimpleCredentials
                                            .forgotUsername.url
                                    )}
                                    rel="noopener noreferrer"
                                    size="medium"
                                    data-qa-id="forgot-username-link"
                                    display="block"
                                >
                                    {
                                        text.EnterSimpleCredentials
                                            .forgotUsername.text
                                    }
                                </Link>
                            </Box>
                            <Box>
                                <Link
                                    href={`${
                                        text.EnterSimpleCredentials.forgotPhrase
                                            .url
                                    }?${search.toString()}`}
                                    rel="noopener noreferrer"
                                    size="medium"
                                    data-qa-id="forgot-password-link"
                                    display="block"
                                >
                                    {
                                        text.EnterSimpleCredentials.forgotPhrase
                                            .text
                                    }
                                </Link>
                            </Box>
                        </Flex>
                    </form>
                </>
            )}
        </Formik>
    );
};

EnterSimpleCredentialsForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    setDisabled: PropTypes.func.isRequired,
    hasError: PropTypes.bool,
    locationSearch: PropTypes.string.isRequired
};

EnterSimpleCredentialsForm.defaultProps = {
    hasError: false
};

export default EnterSimpleCredentialsForm;
