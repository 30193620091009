import { setCSRF } from '../../utils/utils';

/**
 * Maps the form response to the next module
 * @returns {*|string}      The type parameter used for routing.
 */

const enterUsernameApi = ({ setCurrentModule, setAdditionalData }, res) => {
    // find out where to get current modle from adn other things
    setCSRF(res);
    setAdditionalData({ sessionToken: res.data.sessionToken });
    setCurrentModule(res);
};

export default enterUsernameApi;
