import { BRANDS, THEME_CONSTANTS } from '@cof/plastic-components';

const { COLORS } = THEME_CONSTANTS;

const amountOfPadding = variant => {
    if (variant !== 'null') {
        return '20px';
    }
    return null;
};

const onClickAndFocusEvent = event => {
    event.currentTarget.setSelectionRange(
        event.currentTarget.value.length,
        event.currentTarget.value.length
    );
};

const iconSetter = variant => {
    return variant !== 'null' ? {} : { display: 'none' };
};

const paddingTopDifference = brand => {
    return brand === 'postOffice' ? '2px' : null;
};

const returnErrorState = tester => {
    return tester ? 'success' : 'error';
};

const greyPhoneHeaderLines = brand => {
    return brand === 'postOffice' ? '#F9F9F9' : '#F5F5F2';
};

const errorBorderColor = (errors, touched, disabled) => {
    return touched && !disabled && errors ? '#CC2427' : null;
};

const disabledInputColor = disabled => {
    return disabled ? '#F5F5F2' : null;
};

const checkedIconColor = brand => {
    switch (brand) {
        case BRANDS.POST_OFFICE:
            return COLORS.postOffice.green650;
        case BRANDS.ASOS:
            return COLORS.ASOS.green650;
        default:
            return COLORS.capitalOne.teal450;
    }
};

const infoIconColour = brand => {
    switch (brand) {
        case BRANDS.ASOS:
            return COLORS.ASOS.purple350;
        default:
            return COLORS.capitalOne.teal600;
    }
};

const phoneIconColour = brand => {
    switch (brand) {
        case BRANDS.ASOS:
            return COLORS.ASOS.purple350;
        default:
            return COLORS.global.grey200;
    }
};

const disabledButtonStyle = brand => {
    return brand === 'postOffice' ? '#CECFCD' : '#CECECB';
};

const textColorOfButton = disabled => {
    return disabled ? '#FFFFFF' : null;
};

const borderBottomStyle = (errorCode, brand) => {
    if (brand === 'postOffice') {
        return !errorCode ? '3px solid #F9F9F9' : 'none';
    }
    return !errorCode ? '3px solid #F5F5F2' : 'none';
};

const marginBottomStyle = errorCode => {
    return !errorCode ? 'lg' : '0';
};

const rightPaddingForDesktop = brand => {
    return brand === 'postOffice' ? 'sm' : 'xs';
};

const rightPaddingForMobileTablet = brand => {
    return brand === 'postOffice' ? 'xs' : '0';
};

const variantConditional = (touched, disabled, errors) => {
    return touched && !disabled && errors ? 'error' : undefined;
};

const themedBackgroundColor = brand => {
    switch (brand) {
        case BRANDS.POST_OFFICE:
            return COLORS.postOffice.grey50;
        case BRANDS.ASOS:
            return COLORS.global.grey50;
        default:
            return COLORS.capitalOne.blue750;
    }
};

// Temporary Font Colour Matcher as Plastic 4.53.0 has incorrect colours
const themedFontColor = brand => {
    switch (brand) {
        case BRANDS.POST_OFFICE:
            return COLORS.postOffice.grey700;
        case BRANDS.ASOS:
            return COLORS.ASOS.grey850;
        default:
            return COLORS.global.white;
    }
};
// Temporary Font Colour Matcher as Plastic 4.53.0 has incorrect colours
const themedHeadingColor = brand => {
    switch (brand) {
        case BRANDS.POST_OFFICE:
            return COLORS.postOffice.grey700;
        case BRANDS.ASOS:
            return COLORS.ASOS.grey850;
        default:
            return COLORS.capitalOne.blue750;
    }
};

export {
    amountOfPadding,
    onClickAndFocusEvent,
    iconSetter,
    returnErrorState,
    paddingTopDifference,
    greyPhoneHeaderLines,
    errorBorderColor,
    disabledInputColor,
    checkedIconColor,
    infoIconColour,
    phoneIconColour,
    disabledButtonStyle,
    textColorOfButton,
    borderBottomStyle,
    marginBottomStyle,
    rightPaddingForDesktop,
    rightPaddingForMobileTablet,
    variantConditional,
    themedBackgroundColor,
    themedFontColor,
    themedHeadingColor
};
