import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
    AccordionItem,
    Box,
    Flex,
    Radio,
    Text,
    THEME_CONSTANTS,
    Paragraph,
    AppStoreLink,
    Link,
    Markdown,
    useBrand,
    Button
} from '@cof/plastic-components';
import PhoneHeader from '../../_molecules/PhoneHeader';

import { onChangeEventForRadio } from '../../utils/utils';
import {
    borderBottomStyle,
    paddingTopDifference
} from '../../utils/styleChanges';
import text from '../../text';

const { TEXT_SIZES } = THEME_CONSTANTS;

const GetActivationCodeForm = ({
    onSubmit,
    disabled,
    setDisabled,
    phoneNumbers
}) => {
    const brand = useBrand();

    const formattedPhoneNumbers = [];

    let index = 0;
    phoneNumbers.forEach(e => {
        const dictionaryElement = JSON.parse(e);
        dictionaryElement.position = index;
        formattedPhoneNumbers.push(dictionaryElement);
        index += 1;
    });

    const mobileNumbers = formattedPhoneNumbers.filter(
        obj => obj.type === 'mobile'
    );

    const landlineNumbers = formattedPhoneNumbers.filter(
        obj => obj.type === 'landline'
    );

    const [checkedNumber, setCheckedNumber] = useState('0');

    return (
        <Formik
            initialValues={{ authField1: checkedNumber }}
            onSubmit={(_values, formik) => {
                setDisabled(true);
                onSubmit(
                    {
                        authField1: checkedNumber
                    },
                    formik
                );
            }}
            enableReinitialize
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} data-qa-id="otp-get-first-form">
                    <Flex
                        flexDirection={['column', 'row']}
                        width={['100%', '100%', '100%']}
                    >
                        <Box
                            pr={[0, null, 'lg']}
                            pl={['sm', null]}
                            flexGrow={[1, 0]}
                            width={[null, '100%']}
                            position="relative"
                            paddingLeft="0rem"
                            style={{
                                paddingRight: 0,
                                borderBottom: 0,
                                borderLeft: 0,
                                borderRight: 0
                            }}
                        >
                            <Box>
                                <fieldset
                                    data-qa-id="otp-get-phone-number-radio"
                                    name="phoneNumber"
                                    value={checkedNumber}
                                    onChange={e => {
                                        onChangeEventForRadio(
                                            e,
                                            setCheckedNumber
                                        );
                                    }}
                                >
                                    {mobileNumbers.length > 0 && (
                                        <PhoneHeader variant="mobile" />
                                    )}
                                    {mobileNumbers.map((details, i) => (
                                        <Box key={`phone-radio-${i}`}>
                                            <Radio
                                                name="phoneNumber"
                                                value={`${details.position}`}
                                                data-qa-id={`phone-radio-${i}`}
                                                key={`phone-radio-${i}`}
                                                checkedValue={checkedNumber}
                                                defaultChecked={i === 0}
                                            >
                                                <Paragraph
                                                    id={`phone-radio-${i}`}
                                                    data-qa-id={`otp-get-mobile-number-${i}`}
                                                    pt={paddingTopDifference(
                                                        brand
                                                    )}
                                                    pb="0"
                                                >
                                                    {details.value}
                                                </Paragraph>
                                            </Radio>
                                        </Box>
                                    ))}
                                    {mobileNumbers.length > 0 && (
                                        <Markdown
                                            mb="sm"
                                            data-qa-id="otp-get-mobile-text"
                                        >
                                            {
                                                text.RequestOTPPage.phoneTypes
                                                    .mobile
                                            }
                                        </Markdown>
                                    )}
                                    {landlineNumbers.length > 0 && (
                                        <PhoneHeader variant="landline" />
                                    )}
                                    {landlineNumbers.map((details, i) => (
                                        <Box
                                            key={`phone-radio-${
                                                i + mobileNumbers.length
                                            }`}
                                        >
                                            <Radio
                                                name="phoneNumber"
                                                value={`${details.position}`}
                                                data-qa-id={`phone-radio-${
                                                    i + mobileNumbers.length
                                                }`}
                                                checkedValue={checkedNumber}
                                                defaultChecked={
                                                    i + mobileNumbers.length ===
                                                    0
                                                }
                                            >
                                                <Paragraph
                                                    data-qa-id={`otp-get-landline-number-${i}`}
                                                    pt={paddingTopDifference(
                                                        brand
                                                    )}
                                                    id={`phone-radio-${
                                                        i + mobileNumbers.length
                                                    }`}
                                                    pb="0"
                                                >
                                                    {details.value}
                                                </Paragraph>
                                            </Radio>
                                        </Box>
                                    ))}
                                    {landlineNumbers.length > 0 && (
                                        <Markdown
                                            mb="sm"
                                            data-qa-id="otp-get-landline-text"
                                        >
                                            {
                                                text.RequestOTPPage.phoneTypes
                                                    .landline
                                            }
                                        </Markdown>
                                    )}
                                </fieldset>
                            </Box>
                        </Box>
                    </Flex>

                    <Flex>
                        <Box
                            flexGrow={[1, 0]}
                            minWidth={[0, '100%']}
                            style={{
                                borderBottom: borderBottomStyle(false, brand)
                            }}
                        >
                            <Box display="flex" justifyContent="center">
                                <Button
                                    id="submitButton"
                                    type="submit"
                                    width={['100%', '256px', '256px']}
                                    data-qa-id="otp-get-first-submit-button"
                                    mb="md"
                                    disabled={disabled}
                                    loading={disabled}
                                >
                                    {text.RequestOTPPage.submit}
                                </Button>
                            </Box>

                            <Paragraph mb="sm" data-qa-id="otp-get-info-text">
                                {text.RequestOTPPage.help.underButton}
                            </Paragraph>
                        </Box>
                    </Flex>
                    <Flex>
                        <Box>
                            <AccordionItem
                                heading={
                                    text.RequestOTPPage.numbers.phoneFAQ.summary
                                }
                                mt={['xs', null, 'sm']}
                                data-qa-id="otp-get-first-accordion"
                                style={{ padding: '0' }}
                            >
                                <Box>
                                    <Text
                                        size={TEXT_SIZES.MEDIUM}
                                        data-qa-id="otp-get-first-accordion-text-above"
                                    >
                                        {
                                            text.RequestOTPPage.numbers.phoneFAQ
                                                .detailsAbove
                                        }
                                    </Text>
                                    <Flex
                                        flexDirection="row"
                                        flexWrap="wrap"
                                        my="sm"
                                        data-qa-id="otp-get-first-accordion-links"
                                    >
                                        <AppStoreLink store="ios" mr="2rem" />
                                        <AppStoreLink
                                            store="android"
                                            iconProps={{ id: 'android-svg-1' }}
                                        />
                                    </Flex>
                                    <Text
                                        size={TEXT_SIZES.MEDIUM}
                                        data-qa-id="otp-get-first-accordion-text-below"
                                    >
                                        {
                                            text.RequestOTPPage.numbers.phoneFAQ
                                                .detailsBelow
                                        }{' '}
                                    </Text>
                                    <Link
                                        data-qa-id="otp-get-number-link"
                                        href="tel:+443444810884"
                                    >
                                        0344&nbsp;481&nbsp;0884
                                    </Link>
                                </Box>
                            </AccordionItem>
                        </Box>
                    </Flex>
                </form>
            )}
        </Formik>
    );
};

GetActivationCodeForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    setDisabled: PropTypes.func.isRequired,
    phoneNumbers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
};

export default GetActivationCodeForm;
