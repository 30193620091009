export const localStorageFallbackProperty = '__local_storage_fallback__';

const localStorageAvailable = () => {
    // check whether localStorage is available; based on:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    let storage;
    try {
        storage = window.localStorage;
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch {
        return false;
    }
};

const ensureLocalStorageFallback = () => {
    if (!window.hasOwnProperty(localStorageFallbackProperty)) {
        window[localStorageFallbackProperty] = {};
    }
};

const get = key => {
    if (localStorageAvailable()) {
        return window.localStorage.getItem(key);
    }

    ensureLocalStorageFallback();
    return window[localStorageFallbackProperty][key];
};

const set = (key, value) => {
    if (localStorageAvailable()) {
        window.localStorage.setItem(key, value);
    } else {
        ensureLocalStorageFallback();
        window[localStorageFallbackProperty][key] = value;
    }
};

export default {
    get,
    set
};
