import { BRANDS } from '@cof/plastic-components';
import getBranch from './getBranch';

const appendBrandParam = (brand, pathname) => {
    if (brand && brand !== BRANDS.CAPITAL_ONE) {
        return pathname.includes('?')
            ? `${pathname}&channel=${brand}${getBranch('&')}`
            : `${pathname}?channel=${brand}${getBranch('&')}`;
    }
    return `${pathname}${getBranch('?')}`;
};

export default appendBrandParam;
