import React from 'react';
import {
    PageRowNoContentPadding,
    useBrand,
    BRANDS,
    BrandedHeader,
    BrandedLogo,
    CapitalOneLogoPrimaryLight,
    Header as PlasticHeader,
    PostOfficeLogoPrimary,
    ASOSLogoPrimary
} from '@cof/plastic-components';
import { themedBackgroundColor } from '../../utils/styleChanges';

const Header = () => {
    const brand = useBrand();
    return (
        <>
            <PageRowNoContentPadding
                wrapperBg={themedBackgroundColor(brand)}
                pb={['5.2rem', '8.4rem']}
                py={['lg']}
                data-qa-id="header-container"
                role="banner"
            >
                <BrandedLogo
                    display="block"
                    margin="auto"
                    brandConfig={{
                        [BRANDS.CAPITAL_ONE]: props => (
                            <a
                                data-qa-id="capital-one-link"
                                aria-label="capital-one-link"
                                href="https://www.capitalone.co.uk"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <CapitalOneLogoPrimaryLight
                                    flexShrink={0}
                                    width={['225px', '225px', '300px']}
                                    {...props}
                                />
                            </a>
                        ),
                        [BRANDS.POST_OFFICE]: props => (
                            <PostOfficeLogoPrimary
                                flexShrink={0}
                                width="150px"
                                {...props}
                            />
                        ),
                        [BRANDS.ASOS]: props => (
                            <a
                                data-qa-id="asos-link"
                                aria-label="asos-link"
                                href="https://www.asos.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <ASOSLogoPrimary
                                    flexShrink={0}
                                    width={['83px', null, '150px']}
                                    my={[null, null, 'lg']}
                                    {...props}
                                />
                            </a>
                        )
                    }}
                />
            </PageRowNoContentPadding>
        </>
    );
};

export const BorderedHeader = () => {
    const brand = useBrand();

    if (brand === BRANDS.ASOS) {
        return (
            <PlasticHeader
                bg={[
                    themedBackgroundColor(brand),
                    themedBackgroundColor(brand)
                ]}
                bordered
            >
                <ASOSLogoPrimary
                    flexShrink={0}
                    width={['83px', null, '150px']}
                />
            </PlasticHeader>
        );
    }
    return <BrandedHeader bordered />;
};

export default Header;
