import { setCSRF, getErrorForWarningText } from '../../utils/utils';

/**
 * Maps the form response to the next module
 * @returns {*|string}      The type parameter used for routing.
 */

const enterActivationCodeApi = (
    { setCurrentModule, setAdditionalData },
    res
) => {
    setCSRF(res);
    setAdditionalData({
        cpa: res.data.cpa,
        phoneNumberData: res.data.formSchema[0].choices,
        sessionToken: res.data.sessionToken,
        warningCode: res.data?.warningCode,
        errorMessage: getErrorForWarningText(res),
        hasError: !!res.data?.warningCode
    });
    setCurrentModule(res);
};

export default enterActivationCodeApi;
