import React from 'react';
import {
    THEME_CONSTANTS,
    Box,
    Flex,
    Text,
    useBrand,
    CrossIcon,
    CheckCircledIconTwoTone
} from '@cof/plastic-components';
import PropTypes from 'prop-types';
import { checkedIconColor } from '../../utils/styleChanges';

const { COLORS } = THEME_CONSTANTS;

const Statement = ({ isValid, text, qaId, mb, mt }) => {
    const brand = useBrand();
    return (
        <Flex data-qa-id={`${qaId}-indicator`} mb={mb} mt={mt} display="block">
            <Flex data-qa-id={`${qaId}-${isValid ? 'success' : 'error'}-icon`}>
                <Box pb="md" pr="lg">
                    {isValid ? (
                        <CheckCircledIconTwoTone
                            svgProps={{
                                accentColor: checkedIconColor(brand)
                            }}
                            width="3.2rem"
                            height="3.2rem"
                            style={{ position: 'absolute' }}
                            color={COLORS.global.white}
                        />
                    ) : (
                        <CrossIcon
                            width="2.4rem"
                            height="2.4rem"
                            mt="0.3rem"
                            px="0.3rem"
                            style={{ position: 'absolute' }}
                            color={COLORS.capitalOne.red550}
                        />
                    )}
                </Box>
                <Box m="0.3rem">
                    <Text data-qa-id={`${qaId}-indicator-text`}>{text}</Text>
                </Box>
            </Flex>
        </Flex>
    );
};

Statement.defaultProps = {
    isValid: true,
    mb: 'sm',
    mt: '0'
};

Statement.propTypes = {
    isValid: PropTypes.bool,
    text: PropTypes.string.isRequired,
    qaId: PropTypes.string.isRequired,
    mb: PropTypes.string,
    mt: PropTypes.string
};

export default Statement;
